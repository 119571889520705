 import { NavLink } from "react-router-dom"
export const Homedata = {
demoDescription : " At SPRING Lab, our focus is on Automatic Speech Recognition and allied areas of Self-Supervised Learning, Speaker Normalisation, Verification and Adaptation. All the work developed in our Lab are available in open-source in the form of data, models, APIs and recipes.",

professorDemoDescription : " SPRING Lab IIT Madras is headed by Prof. S. Umesh and is part of the Dept. of Electrical Engg. In the past , Prof. Umesh has been a faculty member at IIT Kanpur as well as a Visiting Professor at AT&T Research Laboratories, USA, Cambridge University, UK and RWTH-Aachen, Germany. Between 2010-2016, he led a multi-institution consortium to develop ASR systems in Indian languages in the agriculture domain. He is currently leading the ASR efforts for the National Language Translation Mission (Bhashini project) and is co-coordinator for Speech Technology Consortium of 21 institutions of NLTM.",

// speechtospeechDescription: "Technology that enables a person to speak in one language and have their speech translated into another. We have built a cascade system consisting of Speech recognition, Machine Translation, and Text-to-Speech systems. We are also exploring end-to-end speech-to-text and speech-to-speech systems. This technology can be used for cross-lingual communication.  ",
speechtospeechDescription:"We have built a cascade system consisting of Speech recognition, Machine Translation, and Speech-to-Speech systems.",
ASRDescription: "Automatic Speech Recognition is the process of deriving the transcription of an utterance, given the speech waveform.",

// TSSDescription :"TSS transforms any text into speech in real time. It uses speech synthesis to create the artificial production of speech.",
TSSDescription:"TTS is the process of deriving synthetic speech for a given transcription ",
videotovideoDescription : "Automatically translating video from one language to another, while preserving the audio-video sync.",

footerDescription : "Revolutionizing the way we communicate through innovation and research in speech technologies.",

contactDescription : "Dept of Electrical Engineering, Indian Institute of Technology - Madras, Chennai 600 036 ",

iitMailid:"speechiitm@ee.iitm.ac.in",

gmailid:"speechiitm@gmail.com",

demo1headingDesc:`Our recent ${<NavLink className="text-blue-500">ccc-wave2vec2.0</NavLink>} and data2vec-aqc models are showcased on the leaderboard, demonstrating their impressive performance.`,
demo1heading2:"SPRING LAB SSL models Librispeech960",
demo1headingDesc2:`You can download the pretrained and fine-tuned ccc-wav2vec2.0 and data2vec-aqc model built using the Librispeech-960 hours data. These models are currently ranked 5th and 6th on Public Challenge of SUPERB ${<NavLink to="/demo3" className="text-blue-700">{'Leaderboard'}</NavLink>} `,
demo1box2Content:`Using the Indian languages ASR data, we also fine-tune two publicly available SSL models, namely the Massively Multilingual Speech and IndicWav2vec. You can download these SPRING LAB fine-tuned models in the links given below`,



}
