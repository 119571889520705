import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { HomeUrl } from "../constants/url/HomePageUrl";
import { loginContext } from "../Redux/Slice/UserSlice";
import authService from "../service/authService";

const TableLink = (props) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const downloadUrl = (url) => {
    if (authService.checkUserAlreadyLogin()) {
      authService.downloadUrl(url);
      return;
    }
    dispatch(
      loginContext({
        isshow: true,
        redirectUrl: "",
      })
    );
  };
  return (
    <div
      className={`${
        props.name === "Coming soon" || props.link === ""
          ? "text-[#676569] text-[14px] cursor-default"
          : "text-blue-600 text-[14px]"
      } `}
      onClick={() => {
        if (props.link !== "") {
          downloadUrl(`${HomeUrl.redirectUrl}?url=${props.link}`);
        }
      }}
    >
      {props.link === "" ? "-" : props.name}
    </div>
  );
};

export default TableLink;
