import React from 'react'
import TableLink from '../../../../TableLink'
import "../../Body.css"

const table2Json = require("../../../../../constants/demo1/model/table2.json")


export const SecondTable = () => {

    return (
        <div className="w-full  rounded-lg bg-[#FFF] tableAlignment">

            <table className="w-full ">
                <thead
                    className="w-full text-[#363636] text-[16px] font-normal not-italic bg-gray-100 tableAlignment ">

                    <tr>
                        <th>Models/Language </th>
                        <th>Hindi </th>
                        <th>Bhojpuri</th>
                        <th>Bengali</th>
                        <th>Tamil</th>
                        <th>Telugu</th>
                        <th>Gujarati </th>
                        <th>Kannada</th>
                        <th>Malayalam</th>
                        <th>Marathi</th>
                        <th>Punjabi </th>
                        <th>Odia </th>
                        <th>SansKrit </th>
                        <th>Urdu </th>
                        
                    </tr>
                </thead>
                <tbody className="text-[#363636] font-normal text-sm not-italic text-center ">

                    {table2Json.map(item => {
                        return (
                            <tr key={item.model}  className='cursor-pointer '>
                                <td className='text-[#030F30] pointer-events-none' >
                                    {item.model}</td>
                                <td className={`${item.Hindi.name === "-" ? "pointer-events-none  " : ""}`} >
                                    <TableLink name={item.Hindi.name} link={item.Hindi.link} />
                                </td>

                                <td className={`${item.Bhojpuri.name === "-" ? "pointer-events-none  " : ""}`} >
                                    <TableLink name={item.Bhojpuri.name} link={item.Bhojpuri.link} />
                                </td>

                                <td className={`${item.Bengali.name === "-" ? "pointer-events-none  " : ""}`}>
                                    <TableLink name={item.Bengali.name} link={item.Bengali.link} />


                                </td>
                                <td className={`${item.Tamil.name === "-" ? "pointer-events-none" : ""}`}>
                                    <TableLink name={item.Tamil.name} link={item.Tamil.link} />


                                </td>
                                <td className={`${item.Telugu.name === "-" ? "pointer-events-none  " : ""}`}>
                                    <TableLink name={item.Telugu.name} link={item.Telugu.link} />

                                </td>

                                <td className={`${item.Gujarati.name === "-" ? "pointer-events-none  " : ""}`}>
                                    <TableLink name={item.Gujarati.name} link={item.Gujarati.link} />

                                </td>
                                <td className={`${item.Kannada.name === "-" ? "pointer-events-none  " : ""}`}>
                                    <TableLink name={item.Kannada.name} link={item.Kannada.link} />

                                </td>
                                <td className={`${item.Malayalam.name === "-" ? "pointer-events-none  " : ""}`}>
                                    <TableLink name={item.Malayalam.name} link={item.Malayalam.link} />

                                </td>
                                <td className={`${item.Marathi.name === "-" ? "pointer-events-none " : ""}`}>
                                    <TableLink name={item.Marathi.name} link={item.Marathi.link} />

                                </td>
                                <td className={`${item.Punjabi.name === "-" ? "pointer-events-none " : ""}`}>
                                    <TableLink name={item.Punjabi.name} link={item.Punjabi.link} />


                                </td>

                                <td className={`${item.Odia.name === "-" ? "pointer-events-none " : ""}`}>
                                    <TableLink name={item.Odia.name} link={item.Odia.link} />
                                </td>
                                <td className={`${item.Sanskrit.name === "-" ? "pointer-events-none  " : ""}`}>
                                    <TableLink name={item.Sanskrit.name} link={item.Sanskrit.link} />

                                        </td>
                                        <td className={`${item.Urdu.name === "-" ? "pointer-events-none  " : ""}`}>
                                    <TableLink name={item.Urdu.name} link={item.Urdu.link} />

                                        </td>
                            </tr>
                        )

                    })}



                </tbody>
            </table>
        </div>
    )
}
