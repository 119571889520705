import React from 'react'
import ProjectsAndInternPosition from './ProjectsAndInternPosition'
import LiveProjects from './LiveProjects'
import ProjectsDetails from './ProjectsDetails'
import { ImageUrl } from '../../constants/url/ImagePath'
function AvailableProjects() {
    return (
        <div>
            <div className='bg-[#ECF2FE] w-full min-h-20 mt-14 rounded-[15px] relative'>
                <div className='hidden xl:flex justify-end '>
                    <img src={ImageUrl.largeBlueLeaf} className="absolute bottom-[410px] 2xl:bottom-[500px] right-16 2xl:right-28  2xl:w-20" width={55} alt="largeBlueLeaf" />
                    <img src={ImageUrl.largeRedLeaf} className="absolute  2xl:w-20" width={55} alt="largeRedLeaf" />
                </div>

                <div className='lg:flex p-7 '>
                    <div className='w-full'>
                        <ProjectsAndInternPosition />
                    </div>

                    <div className='w-full pt-10  '>
                        <LiveProjects />
                    </div>

                </div>

            </div>
            <div className='mt-5'>
                <ProjectsDetails />
            </div>

            <div className='w-full'>

            </div>
        </div>
    )
}

export default AvailableProjects