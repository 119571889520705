import React, { useState } from 'react'
import LoginForm from './LoginForm'
import RegisterForm from './RegisterForm'
import { useSelector, useDispatch } from "react-redux";


const LoginPoup = (props) => {
    const [isRegisterForm, setIsRegisterForm] = useState(false)
    const openPopup  = useSelector((state) =>  state.user.isshow)



    return (
        <div>
            {openPopup && <div className="relative z-50" aria-labelledby="modal-title" role="dialog" aria-modal="true">

                <div className="fixed inset-0 transition-opacity bg-black bg-opacity-75"></div>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">

                        {!isRegisterForm ?
                            <LoginForm  setIsRegisterForm={setIsRegisterForm} redirectUrl= {props.redirectUrl} />
                            :
                            <RegisterForm  setIsRegisterForm={setIsRegisterForm} />
                        }
                    </div>
                </div>
            </div>}

        </div>



    )
}

export default LoginPoup


