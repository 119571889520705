import React from "react";
import "../../Style/Header.css";
import { ImageUrl } from "../../constants/url/ImagePath";
import Navbar from "../Shared/Navbar";
import HeaderContent from "./HeaderContent";
import { NavLink } from "react-router-dom";
import { HomeUrl } from "../../constants/url/HomePageUrl";
function Header() {
  return (
    <div className="">
      <div className="headerDiv ">
        <Navbar />

        <div className="flex flex-row pt-20">
          <div className="w-1/5 hidden md:flex">
            <img
              src={ImageUrl.waveImage}
              alt=""
              className="w-full float-left mt-5 "
            />
          </div>
          <div className="w-full md:w-3/5 font-semibold not-italic mainHeading ">
            <div className="text-center text-xs-1 sm:text-lg-1 md:text-md-1 lg:text-lg-1 xl:text-xl-1 2xl:text-6xl-1 headingTxt">
              SPRING-INX{" "}
              <span className="textChange headingTxt"> Models and Data</span>
              &nbsp;
              <span className="textChange headingTxt"> </span>
            </div>

            <div className="text-center text-xs-1 sm:text-lg-1 md:text-md-1 lg:text-lg-1 xl:text-xl-1 2xl:text-6xl-1 md:mt-2 headingTxt">
              {" "}
              for
              <span>
                {" "}
                Indian <span className="textChange headingTxt">Languages</span>
              </span>
            </div>
          </div>
          <div className="w-1/5 hidden md:flex">
            <img
              src={ImageUrl.waveImage}
              alt=""
              className="w-full float-right mt-5"
            />
          </div>
        </div>
        <div className="pt-4 md:pt-6 ">
          <div className="flex flex-wrap justify-center mx-auto">
            <div className="w-full  px-2 md:px-0">
              <p className=" hidden md:block text-center px-52 text-sm-headSmallText sm:text-md-head_des md:text-md-head_des 2xl:lg-recent_head xl:text-xl-head_des 2xl:text-2xl-head_des 3xl:text-3xl font-light  descriptionText ">
                SPRING Lab of IIT Madras is leading a Speech R&D consortium of
                23 institutions as part of the Bhashini (NLTM)
                <NavLink
                  to={HomeUrl.consortium}
                  target="_blank"
                  className="text-blue-500"
                >
                  &nbsp;project.
                </NavLink>{" "}
                {/* <NavLink
                  to={HomeUrl.data2vec_aqc}
                  target="_blank"
                  className="text-blue-500"
                >
                  data2vec-aqc.
                </NavLink> */}
              </p>
            </div>
          </div>
          <div>
            <HeaderContent />
          </div>

          {/* <div className="relative bottom-10 lg:bottom-32 overflow-hidden whitespace-nowrap w-full">
            <div className="inline-block animate-marquee">
              <p className="font-medium text-[#CE5347] flex gap-x-2 text-sm sm:text-base md:text-[18px] lg:text-xl xl:text-2xl">
                We are excited to announce the release of a second set of new
                data, now available in 8 languages with a total of 1300
                additional hours of content! Explore the latest data
                <NavLink
                  className="text-white underline"
                  to={`${HomeUrl.dataset}`}
                >
                  here
                </NavLink>
              </p>
            </div>
          </div> */}

          <div className="relative bottom-10 lg:bottom-32 overflow-hidden w-full flex flex-col justify-center px-2">
            <div className="inline-block animate-marquee">
              <p className="font-medium text-center lg:whitespace-nowrap text-[#CE5347] gap-x-2 text-sm sm:text-base md:text-[18px] lg:text-xl xl:text-2xl">
                We are excited to announce the release of a second set of new
                data, now available in 8 languages with a total of 1300
                additional hours of content! Explore the latest data &nbsp;
                <span>
                  <NavLink
                    className="text-white underline"
                    to={`${HomeUrl.dataset}`}
                  >
                    here
                  </NavLink>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
// md:w-1/2
