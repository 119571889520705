import React from 'react'
import "../../Body.css"
import GetDataAscendingOrder from '../../../../../service/sortService';
import TableLink from '../../../../TableLink';

const tableData = require("../../../../../constants/demo1/model/table1.json");
const sortedtableData = GetDataAscendingOrder(tableData, "Languages_Models")


const FirstTable = () => {

    return (
        <div className="w-full  rounded-2xl bg-[#FFF] tableAlignment">

            <table className="w-full ">
                <thead
                    className="w-full text-[#363636] text-[16px] font-normal not-italic bg-gray-100 tableAlignment ">
                    <tr>
                        <th>
                            <div className="flex items-center justify-center gap-x-2">
                                <div> Languages/Models</div>

                            </div>
                        </th>
                        <th>
                            <div className="flex items-center justify-center gap-x-2">
                                <div> data2vec_aqc</div>

                            </div>
                        </th>
                        <th>
                            <div className="flex items-center justify-center gap-x-2">
                                <div> ccc_wav2vec</div>

                            </div>
                        </th>
                        <th>
                            <div className="flex items-center justify-center gap-x-2">
                                <div>data2vec</div>

                            </div>
                        </th>
                        <th>
                            <div className="flex items-center justify-center gap-x-2">
                                <div> wav2vec2</div>

                            </div>
                        </th>
                        <th>
                            <div className="flex items-center justify-center gap-x-2">
                                <div>HuBERT</div>

                            </div>
                        </th>
                        <th>
                            <div className="flex items-center justify-center gap-x-2">
                                <div>dictionary</div>

                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody className="text-[#363636] font-normal text-[13px] not-italic text-center ">
                    {sortedtableData.map(item => {
                        return <tr key={item.Languages_Models}  className="cursor-pointer">
                            <td className='text-[#030F30] pointer-events-none'  >
                                {item.Languages_Models}
                            </td>

                            <td  className={`${item.data2vec.name==="Coming soon"?" pointer-events-none":" "}`}>
                                <TableLink name={item.data2vec_aqc.name} link={item.data2vec_aqc.link} />
                            </td>

                            <td className={`${item.ccc_wav2vec.name==="Coming soon"?" pointer-events-none":" "}`} >
                                <TableLink name={item.ccc_wav2vec.name} link={item.ccc_wav2vec.link} />
                            </td>

                            <td   className={`${item.data2vec.name==="Coming soon"?" pointer-events-none":" "}`}>
                                <TableLink name={item.data2vec.name} link={item.data2vec.link} />
                            </td>

                            <td  className={`${item.wav2vec.name==="Coming soon"?" pointer-events-none":" "}`} >
                                <TableLink name={item.wav2vec.name} link={item.wav2vec.link} />
                            </td>

                            <td  className={`${item.hubert.name==="Coming soon"?" pointer-events-none":" "}`} >
                                <TableLink name={item.hubert.name} link={item.hubert.link} />

                            </td>

                            <td  className={`${item.dictionary.name==="Coming soon"?" pointer-events-none":" "}`}>
                                <TableLink name={item.dictionary.name} link={item.dictionary.link} />
                            </td>
                        </tr>
                    })}

                </tbody>
            </table>
        </div>
    )
}

export default FirstTable