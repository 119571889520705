import React from 'react'
import { NavLink } from 'react-router-dom'
import { ImageUrl } from '../../constants/url/ImagePath'
import { Homedata } from '../../constants/data/HomePageData'
import '../../Style/Profosser.css'
function Profosser() {
    return (
        <div className=' px-4 2xl:py-20 md:px-20  pt-2'>
            <div className='grid sm:grid-cols-2 md:grid-cols-2 sm:gap-10 md:gap-10' >
                <div className='w-full sm:h-0'>
                    <img src={ImageUrl.largeBlueLeaf} alt="largeBlueLeaf" className='w-16 relative top-24 left-3' />
                    <img src={ImageUrl.largeRedLeaf} alt="largeRedLeaf" className='w-16 relative left-24 top-10' />
                    <img src={ImageUrl.profossorUmesh} alt="profossorUmesh" className='profossorImage object-cover' />

                    <img src={ImageUrl.profossorDetail} alt="" className='hidden  xl:flex w-64 profossorDetail rounded-xl' />
                    {/* <div className="hidden bg-white xl:flex flex-col item-between profossorDetail min-h-[140px] rounded-sm w-[300px] overflow-hidden">
                        <div className="bg-white flex flex-col gap-[3px]" style={{ padding: '18px' }}>
                            <div ><span className="font-bold text-[19px] text-[#1D293F]">Dr.S.Umesh</span> <br />
                                <span className="text-[#8C97AC] text-[14px] font-semibold">Professor</span>
                            </div>
                            <hr className='border-top: 1px border-dashed w-full text-[#E2E4E8]' />
                            <div>
                                <span className="text-[14px] font-[Poppins] text-[#1D293F] not-italic whitespace-nowrap font-bold">Department of Electrical Engineering  </span><br />
                                <span className="text-[14px] whitespace-nowrap font-normal not-italic text-[#1D293F]">Indian Institute of Technology - Madras</span>
                            </div>
                        </div>
                        <svg height="50" >
                            <path d="M 10 50 H 300 V 0 " fill="#ECF2FE" />
                        </svg>
                    </div> */}

                </div>
                <div className=' text-center sm:text-start pt-14 sm:pt-52 md:pt-52 lg:pt-52 xl:pt-32 2xl:pt-32 w-full'>
                    <div className='lg:text-lg-speechHead  xl:text-xl-speechHead 2xl:text-2xl-speechHead font-normal not-italic teamSpeechLabText '>
                    Team SPRING Lab <span className='text-[18px]'>(Formerly Speech Lab)</span>
                    </div>
                    <div className='2xl:mt-4'>
                        <span className='text-sm-1 sm:text-lg-1 md:text-md-1 xl:text-xl-speechLap 2xl:text-2xl-speechLap md:font-medium text-gray-800'> People at</span>  <span className='speechLapText text-sm-1 sm:text-lg-1 md:text-md-1 xl:text-xl-speechLap 2xl:text-2xl-speechLap not-italic font-bold md:font-bold text-base md:text-4xl'>SPRING Lab, IITM</span>
                    </div>
                    <div className='font-normal text-[#666666] text-sm-head_des sm:text-md-loremText  md:text-md-loremText lg:text-lg-speechlaptext xl:text-xl-speechlaptext  2xl:text-2xl-speechlaptext  not-italic checoutDemoText min-h-10 pt-4 2xl:pt-6 xl:w-full '>
                        {Homedata.professorDemoDescription}
                    </div>
                    <NavLink to="https://speech-lab-iitm.github.io/index.html" target="_blank" className='pt-5 2xl:pt-10 pb-10 flex justify-center md:justify-start'>
                        <button className='w-28 h-10 md:w-28 md:h-10 lg:w-36 lg:h-12 xl:w-48 xl:h-14 2xl:w-56 2xl:h-16 3xl:w-64 3xl:h-18 rounded-full text-white font-bold text-sm-learnMoreBtn lg:text-lg-learnMoreBtn xl:text-xl-learnMoreBtn  2xl:text-2xl-learnMoreBtn not-italic learnMoreBtn'>Know More</button>
                    </NavLink>
                </div>
            </div>
        </div>
    )
}

export default Profosser