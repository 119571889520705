import React from 'react'
import '../../Style/DemoDescription.css'
import { ImageUrl } from '../../constants/url/ImagePath'
import { HomeUrl } from '../../constants/url/HomePageUrl'
import { Homedata } from '../../constants/data/HomePageData'
import { NavLink } from 'react-router-dom'
import authService from '../../service/authService'
import { useDispatch } from 'react-redux'
import { loginContext } from '../../Redux/Slice/UserSlice'


function DemoDescription() {
    const dispatch = useDispatch()
    const handleClick = (url) => {
        if(authService.checkUserAlreadyLogin()){
           authService.opendemoPage(url)
            return
           }
           dispatch(loginContext({
            isshow: true,
            redirectUrl:url
        }))
        

    };
    return (
        <div className='px-4 bg-white md:px-20 lg:px-20 md:p-20 md:pb-2 md:pt-24 2xl:pt-28'>
            <div className='grid grid-cols-1 xl:grid-cols-2'>
                <div className='flex flex-col gap-3 mt-20 sm:mt-20 md:mt-10 xl:mt-48 md:gap-5 xl:gap-10'>
                    <div className='flex justify-center ml-12 sm:justify-center xl:justify-start'>
                        <img src={ImageUrl.waveImage} alt="" className=' 2xl:w-80' />
                    </div>
                    <div className='not-italic font-bold text-center text-gray-800 text-sm-1 sm:text-center xl:text-start sm:text-md-1 md:text-md-1 lg:text-lg-1 xl:text-xl-demo 2xl:text-2xl-demo'>
                        Check out our Demos
                    </div>
                    <div className='font-normal ml-2 xs:ml-8 sm:ml-8 xl:ml-0 md:ml-20 text-[#666666] md:w-[80%] 2xl:w-[84%]  text-sm-head_des sm:text-md-loremText md:text-md-loremText lg:text-lg-speechlaptext xl:text-xl-loremText 2xl:text-2xl-loremText not-italic min-h-10 2xl:mt-5 '>
                        {Homedata.demoDescription}
                    </div>
                    <NavLink to="https://speech-lab-iitm.github.io/docs/" target='_blank' className='flex justify-center md:justify-center xl:justify-start'>
                        <button className='h-10 not-italic font-bold text-white rounded-full w-28 md:w-28 md:h-10 lg:w-36 lg:h-12 xl:w-48 xl:h-14 2xl:w-56 2xl:h-16 3xl:w-64 3xl:h-18 text-sm-learnMoreBtn lg:text-lg-learnMoreBtn xl:text-xl-learnMoreBtn 2xl:text-2xl-learnMoreBtn learnMoreBtn'>Learn More</button>
                    </NavLink>
                </div>


                <div className='grid w-full grid-cols-1 sm:gap-5 md:gap-5 2xl:gap-10 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 md:mt-10 xl:mt-0'>

                    <div className=''>
                        <div className='flex flex-col gap-10 pt-10 2xl:mr-0 sm:gap-5 md:gap-8 xl:gap-6 2xl:gap-10 md:pt-24 '>

                            <div onClick={() => handleClick(HomeUrl.speechUrl)} className='bg-[#3C6FF4] h-full 2xl:h-[420px] cursor-pointer text-center xl:text-start rounded-2xl flex flex-col xl:w-full  2xl:w-full p-4 navigationCard ' >
                                <div className='pt-6 2xl:pt-12 xl:pt-4' >
                                    <img src={ImageUrl.roundedRedWave} alt="" className='2xl:w-20 waveImage' />
                                </div>
                                <div className='pt-6 not-italic font-black text-white 2xl:pt-8 text-sm-header lg:text-lg-header xl:text-xl-header 2xl:text-2xl-header'>
                                    Speech to Speech
                                </div>
                                <div>
                                    <p className='h-40 pt-3 overflow-y-scroll not-italic text-lg-headerText lg:text-lg-headerText xl:text-xl-headerText 2xl:text-2xl-headerText 2xl:leading-10 STSext' style={{ color: "#ddd" }} >
                                        {Homedata.speechtospeechDescription}
                                    </p>
                                </div>
                            </div>

                            <div onClick={() => handleClick(HomeUrl.tts)} className='flex flex-col justify-center p-3 text-center cursor-pointer normalCard xl:text-start rounded-2xl xl:w-full h-80 2xl:w-full 2xl:h-96 navigationCard' >
                            <div >
                                <img src={ImageUrl.roundedWaveImg} alt="roundedWaveImg" className='2xl:w-20 waveImage' />
                                <img src={ImageUrl.pinkWave} alt="pinkWave" className='relative bottom-10 left-4 2xl:bottom-14 2xl:left-5 2xl:w-10' />
                            </div>
                            <div className='not-italic font-bold text-white text-sm-header lg:text-lg-header xl:text-xl-header 2xl:text-2xl-header'>
                                <div className='textColor'> TTS</div>
                            </div>
                            <div>
                                <p className='pt-3 not-italic font-normal text-white text-lg-headerText lg:text-lg-headerText xl:text-xl-headerText 2xl:text-2xl-headerText' style={{ color: "#858181" }}>
                                    {Homedata.TSSDescription} {/* Lorem ipsum dolor sit <br /> amet, consectetur <br /> adipiscing elit. ectetur <br /> adipiscing elit. */}
                                </p>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className='flex flex-col gap-10 mt-10 sm:gap-5 md:gap-8 xl:gap-6 2xl:gap-10 md:mt-7'>

                        <div onClick={() => handleClick(HomeUrl.asrUrl)} className='flex flex-col justify-center p-3 text-center cursor-pointer normalCard xl:text-start rounded-2xl xl:w-full h-80 2xl:w-full 2xl:h-96 navigationCard' >
                            <div >
                                <img src={ImageUrl.roundedWaveImg} alt="roundedWaveImg" className='2xl:w-20 waveImage' />
                                <img src={ImageUrl.pinkWave} alt="pinkWave" className='relative bottom-10 left-4 2xl:bottom-14 2xl:left-5 2xl:w-10' />
                            </div>
                            <div className='not-italic font-black text-white text-sm-header lg:text-lg-header xl:text-xl-header 2xl:text-2xl-header'>

                                <div className='textColor'>ASR 2.0 </div>
                            </div>
                            <div>
                                <p className='pt-3 not-italic font-normal text-white text-lg-headerText lg:text-lg-headerText xl:text-xl-headerText 2xl:text-2xl-headerText' style={{ color: "#858181" }}>
                                    {Homedata.ASRDescription}
                                </p>
                            </div>
                        </div>
                        <div onClick={() => handleClick(HomeUrl.videoUrl)} className='flex flex-col justify-center p-3 text-center cursor-pointer normalCard xl:text-start rounded-2xl xl:w-full h-80 2xl:w-full 2xl:h-96 navigationCard' >
                            <div >
                                <img src={ImageUrl.roundedWaveImg} alt="roundedWaveImg" className='2xl:w-20 waveImage' />
                                <img src={ImageUrl.pinkWave} alt="pinkWave" className='relative bottom-10 left-4 2xl:bottom-14 2xl:left-5 2xl:w-10' />
                            </div>
                            <div className='not-italic font-bold text-white text-sm-header lg:text-lg-header xl:text-xl-header 2xl:text-2xl-header'>
                                <div className='textColor'> Video to Video</div>
                            </div>
                            <div>
                                <p className='pt-3 not-italic font-normal text-white text-lg-headerText lg:text-lg-headerText xl:text-xl-headerText 2xl:text-2xl-headerText' style={{ color: "#858181" }}>
                                    {Homedata.videotovideoDescription} {/* Lorem ipsum dolor sit <br /> amet, consectetur <br /> adipiscing elit. ectetur <br /> adipiscing elit. */}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


        </div>
    )
}

export default DemoDescription