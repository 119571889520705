import React from "react";

export default function AluminiDetailsCard({
  name,
  graduation,
  currently,
  past,
}) {
  return (
    <div>
      <div className="w-full flex gap-5">
        <div
          className="w-5 h-5 md:w-10 md:h-10 bg-[#FFE9C5] text-center
                     rounded-full border-[#FFE9C5] font-[Roboto] md:text-[12px] 
                     font-bold border-2 flex items-center text-[#D36B1E] not-italic 
                     leading-10 place-content-center"
        >
          G
        </div>
        <div className="w-full">
          <div className="flex pt-1 gap-1">
            <div className="font-[Poppins] whitespace-nowrap md:whitespace-normal font-bold text-[#CE5347] leading-[1.250] 2xl:leading-[1.350] text-[13px]  2xl:text-[18px]">
              {name}
            </div>
            <div className=" capitalize font-[Poppins] whitespace-nowrap md:whitespace-normal font-normal text-[13px] md:text-[9px] lg:text-[12px] 2xl:text-[17px] leading-[1.250] 2xl:leading-[1.350]">
              {graduation}
            </div>
          </div>
          <div className="font-[Poppins] font-normal text-[13px] md:text-[9px] lg:text-[12px] 2xl:text-[17px] leading-[1.250] 2xl:leading-[1.350]">
            {currently}
          </div>
          <div className="font-[Poppins] font-normal text-[13px] md:text-[9px] lg:text-[12px] 2xl:text-[17px]  leading-[1.250] 2xl:leading-[1.350]">
            {past}
          </div>
        </div>
      </div>
    </div>
  );
}
