import React from 'react'
import GaleryHead from '../component/Gallery/GalleryHead';
import GalleryNav from '../component/Gallery/GalleryNav';
import { ImageUrl } from '../constants/url/ImagePath';
import { Helmet } from 'react-helmet';
function AllGalleryContentLoading() {
    return (
        <div>
            <Helmet>
                <title>Spring Lab, IIT Madras - Gallery</title>
            </Helmet>
            <GaleryHead />
            <GalleryNav />
           <div className='flex justify-center gap-2'>
           <div className='text-[#B3B3B3] mt-5 text-[16px] font-medium'>
                LOAD MORE 
            </div>
            <div className='mt-6'>
                <img src={ImageUrl.downArrow} className="w-4" alt="" />
            </div>
           </div>
        </div>
    )
}
export default AllGalleryContentLoading;