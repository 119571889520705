import React from "react";
import { HomeUrl } from "../../constants/url/HomePageUrl";
import { NavLink } from "react-router-dom";
import authService from "../../service/authService";
import "../../Style/ButtonAnimation.css";
import { loginContext } from "../../Redux/Slice/UserSlice";
import { useDispatch } from "react-redux";

function HeaderContent() {
  const dispatch = useDispatch();
  const tryDemo = () => {
    if (authService.checkUserAlreadyLogin()) {
      authService.opendemoPage(HomeUrl.demoUrl)
      return
    }

    dispatch(loginContext({
      isshow: true,
      redirectUrl:HomeUrl.demoUrl
  }))


  }

  return (
    <div className="flex flex-col px-6 md:flex-row justify-center gap-4 pt-6 2xl:pt-10 pb-16 lg:pb-36">
  <div className="w-full md:w-44 2xl:w-48">
    <NavLink to={'/models'}>
      <div className="p-3 border border-white text-center text-white bg-[#CE5347] font-semibold rounded-lg bling-border">Download Models</div>
    </NavLink>
  </div>
  <div className="w-full md:w-44 2xl:w-48">
    <NavLink to={'/dataset'}>
      <div className="p-3 border border-white font-semibold text-center text-white bg-[#CE5347] rounded-lg bling-border">Download Data</div>
    </NavLink>
  </div>
  <div onClick={tryDemo} className="w-full md:w-44 2xl:w-48">
      <div className="p-3 cursor-pointer font-semibold text-center text-white bg-blue-700  rounded-lg ">Try Demos</div>
  </div>
  
</div>

  );
}

export default HeaderContent;