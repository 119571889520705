import React from 'react'
import OngoingProjects from './OngoingProjects'
import PastProjects from './PastProjects'
function    PastAndGoingProjects() {
    return (
        <div className='md:flex mt-12 gap-5 px-4 md:px-14 py-14'>
            <div className='w-full md:w-2/4'>
                <OngoingProjects />
            </div>
            <div className='w-full md:w-2/4 mt-5 md:mt-0'>
                <PastProjects />
            </div>
        </div>
    )
}

export default PastAndGoingProjects