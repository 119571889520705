import React from 'react'
import Navbar from '../Shared/Navbar'
import { ImageUrl } from '../../constants/url/ImagePath'

function contactHeader() {
    return (
        <div className=''>
            <div className='headerContactDiv '>
                <Navbar />

                <div className='flex flex-row justify-center pt-20'>
                    <div className='hidden md:flex mt-9 2xl:mt-11' >
                        <img src={ImageUrl.waveImage} alt="" className='w-56 2xl:w-full float-left mt-5 ' />
                    </div>
                    <div className=' font-semibold not-italic mainHeading '>
                        <div className='text-center sm:text-sm-1 md:text-md-1 xl:text-xl-1'>Contact Us&nbsp;

                        </div>
                    </div>

                    <div className='hidden md:flex mt-9 2xl:mt-11' >
                        <img src={ImageUrl.waveImage} alt="" className='w-56 2xl:w-full float-right mt-5' />
                    </div>
                </div>
                <div className='flex flex-wrap justify-center mx-auto'>
                        <div className='w-full md:w-1/2 px-2 md:px-0'>
                            <p className='text-center text-sm-headSmallText sm:text-md-head_des md:text-md-head_des 2xl:lg-recent_head xl:text-xl-head_des 2xl:text-2xl-head_des 3xl:text-3xl font-light  descriptionText '>
                            Kit is a professional instrument; here you won’t find any bright interface or colorful layout, only a precise set of tools
                            </p>
                        </div>
                    </div>
            </div>
        </div>
    )
}

export default contactHeader