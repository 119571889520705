import React from 'react'
import { NavLink } from 'react-router-dom';
function ProjectsDetails() {
    return (
        <div className='rounded-[20px] w-full min-h-20 md:p-5'>
            <div className='flex'>
                <div className='w-1/5 whitespace-nowrap md:whitespace-normal font-bold  md:text-[16px]  2xl:text-[20px] not-italic	'>
                    Description :
                </div>
                <div className='w-4/5 text-[#000000] text-[16px] 2xl:text-[20px] font-normal not-italic leading-8 2xl:leading-[43px] pr-10 pb-2 ml-20'>
                    The Speech Processing Lab headed by Prof. S. Umesh in the Department of Electrical Engineering at IIT Madras is seeking applications for the post of Project Associate/Intern. You will be working on Natural Language Translation Mission (NLTM) of the Govt. of India. The objective is to develop Indian Language technology systems and products and deploy them in the field with the help of start-ups. Prof. Umesh is leading the Automatic Speech Recognition part ofthe project. The candidate’s day to day role would involve building state of the art ASR Systems in Indian languages using deep learning ideas including sequence-to-sequence modelling based on CTC, transformers,wav2vec, BERT and GPT as well as conventional DNN, CNN and TDNN.

                    <div className='mt-2'>
                        You will be working on building end-to-end ASR, MT and <span className='text-[#3C6FF4]'> <NavLink to="https://asr.iitm.ac.in/ttsv2" target="_blank" >TTS</NavLink> </span> systems. Click to check out the demos including <span className='text-[#3C6FF4]'> <NavLink to="https://asr.iitm.ac.in/ttsv2" target="_blank">Speech-2-Speech translation</NavLink> </span>
                    </div>
                </div>

            </div>

            <div className='flex border border-l-0 border-r-0 border-b-0 py-2'>
                <div className='w-1/5 whitespace-nowrap md:whitespace-normal font-bold	text-[16px] 2xl:text-[20px] not-italic'>
                    Required Skills :
                </div>
                <div className='w-4/5 ml-20 text-[#000000] text-[16px] 2xl:text-[20px] leading-8 2xl:leading-10  font-normal not-italic '>
                    Python, Deep Learning and PyTorch
                </div>
            </div>

            <div className='flex border border-l-0 border-r-0 border-b-0  py-2'>
                <div className='w-1/5  whitespace-nowrap md:whitespace-normal font-bold	text-[16px] 2xl:text-[20px] not-italic'>
                    Duration :
                </div>
                <div className='w-4/5 ml-20 text-[#000000] text-[16px] 2xl:text-[20px] leading-8 2xl:leading-10 font-normal not-italic'>
                    Variable
                </div>
            </div>


            <div className='flex border border-l-0 border-r-0 border-b-0 py-2'>
                <div className='w-1/5  whitespace-nowrap md:whitespace-normal font-bold	text-[16px] 2xl:text-[20px] not-italic'>
                    Who Can Apply :
                </div>
                <div className='w-4/5 ml-20 text-[#000000] text-[16px] 2xl:text-[20px] leading-8 2xl:leading-10 font-normal not-italic'>
                    Applicants who have B.E./B.Tech or higher degree in Computer Science or Electrical & Electronics Engineering.
                    Have prior knowledge and experience with Deep Learning related problems.
                    Experience with PyTorch.
                </div>
            </div>

            <div className='flex border border-l-0 border-r-0 border-b-0 py-2'>
                <div className='w-1/5 font-bold	text-[16px] 2xl:text-[20px] not-italic'>
                  <span className='break-normal'>  Other Preferred  Skills :</span>
                </div>
                <div className='w-4/5 ml-20 text-[#000000] text-[16px] 2xl:text-[20px] 2xl:leading-10 font-normal not-italic leading-8'>
                    Having in-depth knowledge of fields such as Speech Processing, NLP (Natural Language Processing) or Vision
                    Should be comfortable with working remotely.
                    Comfortable in using Linux, git.
                </div>
            </div>

            <div className='flex border border-l-0 border-r-0 border-b-0 py-2'>
                <div className='w-1/5 whitespace-nowrap md:whitespace-normal font-bold	text-[16px] 2xl:text-[20px] not-italic'>
                    Salary :
                </div>
                <div className='w-4/5 ml-[85px] md:ml-20 text-[#000000] text-[16px] 2xl:text-[20px] 2xl:leading-10 font-normal not-italic leading-8'>
                    Commensurate with experience under IITM rules
                </div>
            </div>

            {/* <div className='flex border border-l-0 border-r-0 border-b-0 py-2'>
                <div className='w-1/5  whitespace-nowrap md:whitespace-normal font-bold	text-[16px] 2xl:text-[20px] not-italic'>
                    Deadline:
                </div>
                <div className='w-4/5 ml-20 text-[#000000] text-[16px] 2xl:text-[20px] 2xl:leading-10 font-normal not-italic leading-8'>
                    Last date to apply is December 5
                </div>
            </div> */}
            {/* <div className='flex border border-l-0 border-r-0 border-b-0 py-2'>
                <div className='w-1/5  font-bold text-[16px] 2xl:text-[20px] not-italic'>
                    Shortlisting Test dates :

                </div>
                <div className='w-4/5 ml-20 text-[#000000] text-[16px] 2xl:text-[20px] 2xl:leading-10 font-normal not-italic leading-8'>
                    There will be two tests. One on December 6th and the other on December 20th. You can appear for either tests, but not both.
                </div>
            </div> */}
            <div className='flex border border-l-0 border-r-0 border-b-0 py-2'>
                <div className='w-1/5 whitespace-nowrap md:whitespace-normal font-bold	text-[16px] 2xl:text-[20px] not-italic'>
                    Contact :
                </div>
                <div className='w-4/5 ml-20 text-[#000000] text-[16px] 2xl:text-[20px] 2xl:leading-10 font-normal not-italic leading-8'>
                    Please send your resume to: <span className='text-[#3C6FF4]'><NavLink to="mailto:speechiitm@ee.iitm.ac.in">speechiitm@ee.iitm.ac.in</NavLink></span>
                </div>
            </div>


        </div>
    )
}

export default ProjectsDetails