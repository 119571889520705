export const ImageUrl = {
  // Team profile images
  arunSvg: "/public/static/Asset/images/arun.svg",
  ashishSvg: "../../static/Asset/images/ashish.svg",
  jordhanSvg: "../../static/Asset/images/jordhan.svg",
  vrundaSvg: "../../static/Asset/images/vrunda.svg",
  vasistaSvg: "../../static/Asset/images/vasista.svg",
  tansirSvg: "../../static/Asset/images/tansir.svg",
  remananSvg: "../../static/Asset/images/remanan.svg",
  nithyaSvg: "../../static/Asset/images/nithya.svg",
  mohammedSvg: "../../static/Asset/images/mohammed.svg",
  koishikdSvg: "../../static/Asset/images/koishik.svg",
  prabakardSvg: "../../static/Asset/images/prabakar.svg",
  metildadSvg: "../../static/Asset/images/metilda.svg",
  malavikaJpg: "../../static/Asset/images/malavika.jpg",
  shoutrikJpg: "../../static/Asset/images/shoutrik.jpg",

  logoSvg: "../../static/Asset/images/logo.svg",
  iitMap: "../../static/Asset/images/Map.svg",
  // wave images
  waveImage: "../../static/Asset/images/waveimg.svg",
  roundedRedWave: "../../static/Asset/images/roundedRedWave.svg",
  // roundedWaveImg:"../../static/Asset/images/roundedWaveImg.svg",
  // pinkWave: "../../static/Asset/images/pinkWave.svg",

  // smallLeaf
  redLeaf: "../../static/Asset/images/redLeaf.svg",
  blueLeaf: "../../static/Asset/images/blueLeaf.svg",
  largeBlueLeaf: "../../static/Asset/images/largeBlueLeaf.svg",
  largeRedLeaf: "../../static/Asset/images/largeRedLeaf.svg",
  // play button
  roundedPlayButton: "../../static/Asset/images/roundPlayBtn.svg",
  colorDot: "../../static/Asset/images/ColorDot.svg",
  dotSvg: "../../static/Asset/images/dot.svg",
  playCircle: "../../static/Asset/images/PlayCircle.svg",

  // profossors

  hema: "../../static/Asset/images/hema.png",
  umesh: "../../static/Asset/images/umesh.png",
  selectBoxArrow: "../../static/Asset/images/selectBoxUpArrow.svg",
  professorUmesh: "../../static/Asset/images/profossorUmesh.png",
  profossorDetail: "../../static/Asset/images/profossorDetail.svg",
  //  gallery image

  image1: "../../static/Asset/images/galleryImage 1.svg",
  image2: "../../static/Asset/images/galleryImage 2.svg",
  image3: "../../static/Asset/images/galleryImage 3.svg",
  image4: "../../static/Asset/images/galleryImage 4.svg",
  image5: "../../static/Asset/images/galleryImage 5.svg",
  image6: "../../static/Asset/images/galleryImage 6.svg",
  image7: "../../static/Asset/images/galleryImage 7.svg",
  image8: "../../static/Asset/images/galleryImage 8.svg",

  // Arrow button

  downArrow: "../../static/Asset/images/downArrow.svg",
  IITmap: "../../static/Asset/images/iitMap.png",

  // image thumbnail

  thubnail: "../../static/Asset/images/umeshthumbnail.png",

  // logo
  logo: "../../static/Asset/images/logo.svg",
  iitLogo: "../../static/Asset/images/iitLogo.svg",

  // navigation card wave images
  roundedWaveImg: "../../static/Asset/images/roundedWaveImg.svg",
  pinkWave: "../../static/Asset/images/pinkWave.svg",

  // profossor umesh
  profossorUmesh: "../../static/Asset/images/profossorUmesh.png",

  previous: "../../static/Asset/images/previous.svg",
  next: "../../static/Asset/images/next.svg",

  searchIcon: "../../static/Asset/images/searchIcon.svg",

  previousIcon: "../../static/Asset/images/previousIcon.svg",
  nextIcon: "../../static/Asset/images/nextIcon.svg",
  linkedInIcon: "../../static/Asset/images/linkediInCircle.png",
  linkedInActive: "../../static/Asset/images/linkedinActive.png",

  // facebook:"../../static/Asset/images/facebookIcon.png",
  // twitter:"../../static/Asset/images/twitterIcon.png",
  // facebookInactive:"../../static/Asset/images/facebook-inactive.png",
  // twitterInactive:"../../static/Asset/images/twitterBlack.png",
  // facebookBlack:"../../static/Asset/images/facebookBlack.png"

  facebookIcon: "../../static/Asset/images/facebookIcon.png",
  twitterIcon: "../../static/Asset/images/twitterIcon.png",
  // linkedInIcon: "../../static/Asset/images/linkediInCircle.png",

  facebookGrayIcon: "../../static/Asset/images/facebookGray.png",
  twitterGrayIcon: "../../static/Asset/images/twitterLightIcon.png",

  // inactive icons
  facebookInactive: "../../static/Asset/images/facebook-inactive.png",
  twitterInactive: "../../static/Asset/images/twitter-inactive.png",

  // svg

  linkedInSvg: "../../static/Asset/images/linkedin_icon.svg",
  facebookSvg: "../../static/Asset/images/facebook .svg",

  // newSvg

  linkedInNew: "../../static/Asset/images/linkedInNew.svg",
  facebookNew: "../../static/Asset/images/facebookNew.svg",
  twitterNew: "../../static/Asset/images/twitterNew.svg",
  huggingFace: "../../static/Asset/images/huggingface.svg",
  githubImg: "../../static/Asset/images/dddd.png",

  // Blurrer svg
  linkedInBlurrer: "../../static/Asset/images/linkedInBlurrer.svg",
  facebookBlurrer: "../../static/Asset/images/facebookBlurrer.svg",
  twitterBlurrer: "../../static/Asset/images/twitterBlurrer.svg",

  // another website

  studentImage1: "../../static/Asset/images/preview1.svg",
  studentImage2: "../../static/Asset/images/preview2.svg",
  roundedImage: "../../static/Asset/images/round.svg",
  wave1: "../../static/Asset/images/wave11.svg",
  languageimg: "../../static/Asset/images/languageImg.svg",
  springLabLogo: "../../static/Asset/images/springlab.svg",
  capSpringLogo: "../../static/Asset/images/capSpringLogo.svg",
  fundingImg: "../../static/Asset/images/funding.svg",
  license: "../../static/Asset/images/license.svg",
  sourceRE: "../../static/Asset/images/sourceImg.svg",
  model2img: "../../static/Asset/images/image1.svg",
  model3img: "../../static/Asset/images/image2.svg",
  model4img: "../../static/Asset/images/image3.svg",
  foundation: "../../static/Asset/images/treeFoundationImg.svg",
  langFoundationImg: "../../static/Asset/images/foundationImg.svg",
  source: "../../static/Asset/images/statice.svg",
  acknowledment: "../../static/Asset/images/acknowledment.svg",
  readme: "../../static/Asset/images/readme.svg",
};
