const url=`https://asr.iitm.ac.in/demo`
// const url = `http://localhost:8778`

const apiUrl = {
  login: { url: `${url}/login`, method: "POST" },
  register: { url:`${url}/login/register`, method: "POST" }
}

const checkUserAlreadyLogin = () => {
  const token = localStorage.getItem("token");

  if (!token) {
    return null; // No token found, return null
  }

  const decodCredential = atob(token.split('.')[1]);
  const userCredential = JSON.parse(decodCredential);

  const expirationTime = userCredential.exp * 1000; // Convert expiration time to milliseconds
  const currentTime = new Date().getTime(); // Get the current time in milliseconds

  if (currentTime > expirationTime) {
    return null; // Token is expired, return null
  }

  // Token is valid, return user data
  return userCredential;
};


const login = async (payload) => {

  return await fetch(apiUrl.login.url, {
    method: apiUrl.login.method,
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
    .catch(error => {
      console.error(error);
    })
}

const logout = () => {
  if (localStorage.getItem("token")) {
    localStorage.removeItem("token");
  }
}

const register = async (payload) => {
  return await fetch(apiUrl.register.url, {
    method: apiUrl.register.method,
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => {
      // Handle the response from the API
      return data;
    })
    .catch(error => {
      // Handle any errors that occurred during the request
      console.error(error);
    });
}

const getToken = () => {
  let token = localStorage.getItem("token");
  if (!token) {
    return null;
  }
  return JSON.parse(token)
}

 const downloadUrl=(url)=>{
 
  if (url.endsWith(".txt")){
    const parts = url.split('/');
    const fileName = parts[parts.length - 1];

    var request = new XMLHttpRequest();
    request.open('GET', `${url}&token=${getToken()}`, true);
    request.responseType = 'blob';
    
    request.onload = function() {
      if (request.status === 200) {
        var blob = request.response;
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    };
    
    request.send();
    
return

}


  const a = document.createElement('a')
  a.href = `${url}&token=${getToken()}`
  a.href =`${url}&token=${getToken()}`
  document.body.appendChild(a);
  a.click();
 }

 const opendemoPage=(pathName)=>{
window.open(`${url}/switchPage?token=${getToken()}&path=${pathName}`,"_blank")
 }

export default { checkUserAlreadyLogin, login, logout, register,getToken,downloadUrl,opendemoPage };