import React from 'react'
import DemoDescription from '../component/HomePage/DemoDescription';
import Profosser from '../component/HomePage/Profosser';
import TeamProfile from '../component/HomePage/TeamProfile';
import VideoScreen from '../component/HomePage/VideoScreen';
import RecentPublications from '../component/HomePage/RecentPublications';
import { Helmet } from 'react-helmet'
import Header from '../component/HomePage/Header';
import authService from '../service/authService';
import { useEffect } from 'react';

export default function AllContentLoaded() {
    function setCookie(name, value, daysToExpire) {
        const date = new Date();
        date.setTime(date.getTime() + (daysToExpire * 24 * 60 * 60 * 1000)); // Set expiration time in milliseconds
      
        const expires = "expires=" + date.toUTCString();
        document.cookie = name + "=" + value + ";" + expires + ";path=/";
      }

    const getSession=()=>{
        console.log("oook");
        setCookie('token', 'john_doe', 7);
    }

    useEffect(()=>{
        authService.checkUserAlreadyLogin()
        // service.login({"email":"mukesh@gmail.com","password":"12345"})
        // const payload= {
        //     "email": "sandheep@gmail.com",
        //     "organization": "NetPhenix",
        //     "mobile": 9486473592,
        //     "password": "12345"
        // }
        // service.register(payload)
    })
    return (
        <div>
            <div>
            <Helmet>
                <title>Spring Lab, IIT Madras - Home</title>
            </Helmet>
            </div>
            <Header />
            <VideoScreen/>
            <RecentPublications />
            <DemoDescription />
            <Profosser />
            <TeamProfile />
            

        </div>
    )
}
