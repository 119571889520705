import React from 'react'
import '../../Style/TeamProfile.css'
import { ImageUrl } from '../../constants/url/ImagePath';
import { NavLink } from "react-router-dom";

function TeamProfile() {

    let teamProfiles = require('../../constants/json/TeamProfileData.json');
    return (
        <div className=" hidden  md:grid grid-cols-1 sm:grid-cols-2  md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5 place-items-center px-10 sm:px-10 md:px-20 sm:mt-20 md:mt-20 ">
            {
                teamProfiles.map((teamProfile, id) => {
                    return (
                        <div key={id} className='  h-72 sm:h-70 w-full md:h-64 xl:h-64 2xl:h-96 rounded-2xl profileBgColor  flex flex-col justify-center items-center'>
                            <img src={teamProfile.img} alt="" className='w-36 sm:w-32 md:w-32 2xl:w-60 xl:w-36' />
                            <p className='pt-3 not-italic font-semibold text-2xl-profileName xl:text-2xl-profileName  profileName'>
                                {teamProfile.name}
                            </p>
                            <div className='flex my-2 gap-2'>
                                <NavLink to={teamProfile.linkedIn_URL} target="_blank">
                                   {
                                    teamProfile.linkedIn_URL?
                                    <img src={ImageUrl.linkedInNew} className={`${teamProfile.linkedIn_URL?'cursor-pointer':'cursor-auto'} w-6 2xl:w-10`} alt="linkedInIcon" />:
                                    <img src={ImageUrl.linkedInBlurrer} className={`${teamProfile.linkedIn_URL?'cursor-pointer':'cursor-auto'} w-6 2xl:w-10`} alt="linkedInBlurrer" />
                                    }
                                </NavLink>
                                <NavLink>
                                    {teamProfile.twitter ?
                                        <img src={ImageUrl.twitterNew} className={`${teamProfile.twitter?'cursor-pointer':'cursor-auto'} w-6 2xl:w-10`} alt="twitterIcon" /> :
                                        <img src={ImageUrl.twitterBlurrer} className={`${teamProfile.twitter?'cursor-pointer':'cursor-auto'} w-6 2xl:w-10`} alt="twitterBlurrer" />
                                    }
                                </NavLink>
                                <NavLink >
                                    {teamProfile.facebook ?
                                        <img src={ImageUrl.facebookNew} className={`${teamProfile.facebook?'cursor-pointer':'cursor-auto'} w-6 2xl:w-10`} alt="facebookIcon" /> :
                                        <img src={ImageUrl.facebookBlurrer} className={`${teamProfile.facebook?'cursor-pointer':'cursor-auto'} w-6 2xl:w-10`} alt="facebookBlurrer" />
                                    }
                                </NavLink>
                                <div>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default TeamProfile