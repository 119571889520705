import React from 'react'
import { useState } from 'react';
import { useRef } from 'react';
import { ImageUrl } from '../../constants/url/ImagePath'
import '../../Style/VideoScreen.css';

function VideoScreen() {
    const [showHideBox, setShowHideBox] = useState(true)
    const sampleVideo = require('../../constants/video/drUmesh.mov');
    const videoRef = useRef('')
    const HandlePlayVideo = () => {
        videoRef.current.play();
        setShowHideBox(false)
    }
    const handlePause = () => {
        videoRef.current.pause();
        setShowHideBox(true)

    }

    return (
        <div className=' w-full px-20 relative md:h-96 bottom-32 2xl:bottom-40' >
            <div className='w-full hidden md:flex h-5 bg-black rounded-t-lg'>
                <div className='pl-6 pt-1'>
                    <img src={ImageUrl.colorDot} alt="" className='w-11' />
                </div>
            </div>
            <video className='md:w-full  rounded-b-xl  hidden md:flex justify-center videoScreen object-cover' src={sampleVideo} ref={videoRef}
                onPause={handlePause} onPlay={HandlePlayVideo}
                poster={ImageUrl.thubnail}
                controls>
            </video>
            {showHideBox ? <div className='hidden xl:block  absolute top-48 2xl:top-64 left-8' >
                <div className='bg-gray-200 xl:min-h-40 xl:w-64 2xl:h-72 2xl:w-96 rounded-lg p-3 xl:p-5 text-[#8b8787] leading-7 flex justify-center items-center 2xl:text-[22px] 2xl:leading-[40px]'>
                    Everything developed in our Lab is available in open-source in the form of data, models, APIs and recipes.
                </div>
            </div> : null}
            {showHideBox ? <div className='hidden xl:block absolute top-16 right-11' >
                <div className='bg-black hidden top-[12px] 2xl:w-96 xl:block w-80 rounded-t-lg relative 2xl:top-[30px]'>
                    <div className='p-1 pl-2'>
                        <img src={ImageUrl.dotSvg} alt="" />
                    </div>
                </div>
                <div className='bg-gray-200 relative top-[12px] 2xl:top-[30px] xl:h-72 xl:w-80 2xl:min-h-[450px] 2xl:w-96 rounded-b-lg p-3 xl:p-5 text-[#8b8787] leading-8 flex justify-center items-center flex-col 2xl:text-[22px] 2xl:leading-[40px]'>
                    <div >
                        Our Self-Supervised Models Data2vec-aqc, ccc-Wave2vec2.0 are currently ranked 5th and 6th in the <a className='text-[blue]' href="https://superbbenchmark.org/leaderboard?subset=Public+Set" target="_blank">Superb benchmark Leadership board  </a>
                    </div>
                    {/* <a className='break-words hover:text-[blue] underline' href="https://superbbenchmark.org/leaderboard?subset=Public+Set">https://superbbenchmark.org/leaderboard?subset=Public+Set</a> */}
                </div>
            </div> : null}
            {/* </> */}
        </div>
    )
}

export default VideoScreen