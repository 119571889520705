import React, { useState } from 'react';
import { useEffect } from 'react';
import { ImageUrl } from '../../constants/url/ImagePath';

function ImageGallery() {
  const ImagUrl = require('../../constants/json/gallery.json')
  const [showImg, setShowImage] = useState('')
  const [showModal, setShowModal] = useState(false);
  const [immmgg, setimmg] = useState([])
  const [currentImage, setCurrentImage] = useState()

  useEffect(() => {
    setimmg(ImagUrl)
  }, [])

  const handleImageClicked = (img, i) => {
    setCurrentImage(i)
    setShowModal(true)
    setShowImage(img)
  }

  const handlePrevious = () => {
    let totalLenght = immmgg.length;
    if (currentImage > 1) {
      setCurrentImage(currentImage - 1)
      setShowImage(immmgg[currentImage - 1].url)
    } else {
      setCurrentImage(totalLenght - 1);
    }

  }

  const handleNext = () => {
    let totalLenght = immmgg.length;
    if (totalLenght - 1 > currentImage) {
      setCurrentImage(currentImage + 1)
      setShowImage(immmgg[currentImage + 1].url)
    } else {
      setCurrentImage(0);
    }
  }

  const handleOutsideClick = () => {
    setShowModal(false)

  }

  return (
    <>
      <div className='grid grid-cols-4 gap-4 my-10' >
        {
          immmgg.map((image, index) => {
            return (
              <div className='w-full' key={index}>
                <img className='h-full w-full object-cover cursor-pointer rounded ' type="button" onClick={() => handleImageClicked(image.url, index)} src={image.url} alt="" />
              </div>
            )
          })
        }


        {showModal ? (
          <>
            <div
              className="justify-center  items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none "
            >
              <div className='h-full  w-full relative flex justify-center items-center' >
                <div className='h-full w-full bg-black absolute opacity-5' onClick={handleOutsideClick}></div>
                <div className=' h-[50%] w-[90%] md:h-[70%] md:w-[70%] bg-gray-900 absolute' >
                  <div className='w-full h-full relative flex justify-center items-center bg-contain bg-no-repeat	bg-center	' style={{ backgroundImage: `url(${showImg})`}}>
                    <div className='w-full flex justify-between absolute'>
                      <img src={ImageUrl.previousIcon} alt="" className='w-12 cursor-pointer ' onClick={handlePrevious} />
                      <img src={ImageUrl.nextIcon} alt="" className="w-12 cursor-pointer" onClick={handleNext} />
                    </div>

                  </div>
                  <div className='flex justify-end bg-white p-2'>
                    <button onClick={handleOutsideClick} className='text-white bg-red-500 p-2 rounded text-[13px]'>CLOSE</button>
                  </div>
                </div>

              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}

      </div>
    </>

  )
}

export default ImageGallery