// export const HomeUrl ={
//     demoUrl : "https://asr.iitm.ac.in/demo/",
//     speechUrl : "https://asr.iitm.ac.in/demo/",
//     asrUrl : "https://asr.iitm.ac.in/demo/",
//     videoUrl: "https://asr.iitm.ac.in/demo/",
//     ttsUrl: "https://asr.iitm.ac.in/demo/"
// }


const url=`https://asr.iitm.ac.in/demo`
// const url = `http://localhost:8778`

export const HomeUrl = {
  redirectUrl: `${url}/redirect`,
  demoUrl: `home`,
  speechUrl: `sts`,
  asrUrl: `asr`,
  videoUrl: `avr`,
  tts: `tts`,
  prosody:'prosody',
  ttt:'ttt',
  dataset:'dataset',


  // redirectUrl:`${url}/redirect/?token=${authService.getToken()}`,
  // demoUrl: "https://asr.iitm.ac.in/demo/home",
  // speechUrl: "https://asr.iitm.ac.in/demo/sts",
  // asrUrl: "https://asr.iitm.ac.in/demo/asr",
  // videoUrl: "https://asr.iitm.ac.in/demo/avr",
  // ttsUrl: "https://asr.iitm.ac.in/demo/",
  // tts: "https://asr.iitm.ac.in/demo/tts",
  // home page

  titile_ccc_wav2vec2_0: "https://arxiv.org/pdf/2210.02592.pdf",
  data2vec_aqc: "https://arxiv.org/pdf/2211.01246.pdf",
  Massively_Multilingual_Speech: "https://github.com/facebookresearch/fairseq/tree/main/examples/mms",
  Indicwav2vec: "https://github.com/AI4Bharat/IndicWav2Vec",
  superbchallenge: "https://superbbenchmark.org/leaderboard?subset=Public+Set",

  consortium:"https://nltm.iitm.ac.in/consortium/searchEngine"


}