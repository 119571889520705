import PublicationType from "../component/Publication/PublicationType"
import Header from "../component/Publication/Header"
import { Helmet } from "react-helmet"
import { useState } from "react"
import { useEffect } from "react"
import PublicationList from "../component/Publication/PublicationList"
import { ImageUrl } from "../constants/url/ImagePath"
import ReactPaginate from 'react-paginate';

export default function Publication() {
   const PublicationLists = require("../constants/json/PublicType.json")
   // let listtt=PublicationLists.slice(0,10)
   // console.log("listtt",listtt);
   const [searchTerm, setSearchTerm] = useState('');
   const [displayData, setDisplayData] = useState([]);
   const [showHideList, setShowHideList] = useState(false);
   const [pageNumber, setPageNumber] = useState(0);
   const [itemsPerPage, setItemsPerPage] = useState(10);

   // const itemsPerPage = 10;
   const pagesVisited = pageNumber * itemsPerPage

   useEffect(() => {
      setDisplayData(PublicationLists)


   }, [])

   const handleSearchChange = (event) => {
      setSearchTerm(event.target.value);
      setShowHideList(true)
      if (event.target.value === "") {
         setShowHideList(false)
      }
   }


   const displayLists = displayData.map((item) => {
      return (
         <PublicationType publicationList={item} key={item.id} />
      )
   })

   return (

      <div className="w-full  flex flex-col">
         <Helmet>
            <title>Spring Lab, IIT Madras - Publications</title>
         </Helmet>
         <Header pagename={"Publications"} />

         <div className="flex md:justify-end justify-center md:px-6 w-full py-3">
            <input value={searchTerm} type="text" placeholder="Search" className="outline-none p-2 w-full md:w-[30%] ml-4 md:ml-0" onChange={handleSearchChange}
            />
            <img src={ImageUrl.searchIcon} alt="searchIcon" width={18} className="relative right-8" />
         </div>

         <div>
            {displayData.map((data, i) => {
               return (
                  <div className="w-full  px-4 md:px-10 flex flex-col gap-y-1" key={i}>
                     {showHideList ? <>
                        {data.list.map((list) => (
                        //   <div key={list.id}>
                            list.author.toLowerCase().includes(searchTerm.toLowerCase()) || list.topic.toLowerCase().includes(searchTerm.toLowerCase()) ?
                              <PublicationList className={list.id} author={list.author} topic={list.topic} key={list.topic} 
                              />
                              : null
                        //   </div>
                        ))}
                     </> : ""}
                  </div>
               );
            })}
         </div>
         {!showHideList ? <div className="w-full  px-4 md:px-10  min-h-[60vh] flex flex-col gap-y-4">
            {
               // displayData.map((item => {
               //    console.log("item", item.list);
               //    return <PublicationType publicationList={item} key={item.id} />
               // }))
               displayLists
            }
         </div> :
            null
         }



      </div>
   )
}