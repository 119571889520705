import React from "react";
import Contact from "./ContactDetail";
import Map from "./Map";
import ContactHeader from "./ContactHeader";
import {Helmet} from 'react-helmet';

export default function LoadedContactMap() {
  return (
    <>
      
      <Helmet>
        <title>Spring Lab, IIT Madras - Contact Us</title>
      </Helmet>
      <ContactHeader />
      <div className="px-4 md:px-20 w-fullsm:px-10 grid sm:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2  gap-16 2xl:gap-24 mt-10 mb-20">
        <Contact />
        <Map />
      </div>
    </>
  );
}
