import React from "react";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { useDispatch } from "react-redux";
import authService from "../../service/authService";
import { loginContext } from "../../Redux/Slice/UserSlice";

export const NavMenu = (props) => {
  const dispatch = useDispatch();
  const [isHover, setIsHover] = useState(false);

  const onRedirect = (url) => {
    if (authService.checkUserAlreadyLogin()) {
      authService.opendemoPage(url);
      window.open(url, "_blank");
      return;
    }

    dispatch(
      loginContext({
        isshow: true,
        redirectUrl: url,
      })
    );
  };

  return (
    <div
      className="relative"
      onMouseOver={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <div className={`flex items-center justify-center rounded-sm gap-x-2`}>
        {props.list.name === "Demo" ? (
          <div
            className="cursor-pointer hover:text-[#CE5347]"
            onClick={() => onRedirect(props.list.navigation)}
          >
            {props.list.name}
          </div>
        ) : (
          <NavLink
            to={props.list.navigation}
            target={props.list.target}
            className={({ isActive }) =>
              `text-sm 2xl:ml-4 whitespace-nowrap ${
                props.list.name === "Forum"
                  ? "cursor-default"
                  : "cursor-pointer"
              } ${
                isActive &&
                props.list.name !== "Resources" &&
                props.list.name !== "People"
                  ? "border-b-4 border-[#CE5347] text-[#CE5347]"
                  : "hover:text-[#CE5347]"
              }`
            }
            isActive={(match, location) => {
              // Highlight Home tab if the path is '/'
              if (props.list.name === "Home" && location.pathname === "/") {
                return true;
              }
              return match;
            }}
          >
            {props.list.name}
          </NavLink>
        )}
        {props.list?.children && props.list?.children.length !== 0 ? (
          isHover ? (
            <IoIosArrowDown />
          ) : (
            <IoIosArrowUp />
          )
        ) : (
          ""
        )}
      </div>
      {isHover && props.list?.children && props.list?.children.length !== 0 && (
        <div
          className={`absolute left-0 bg-gray-200 flex flex-col text-[#030F30] top-full rounded-md`}
        >
          {props.list.children.map((item) => {
            return (
              <NavLink
                to={item.navigation}
                target={item.target}
                key={item.name}
                className={({ isActive }) =>
                  `px-4 py-1 text-sm ${
                    isActive
                      ? "text-[#CE5347] font-semibold"
                      : "hover:text-[#CE5347]"
                  }`
                }
              >
                {item.name}
              </NavLink>
            );
          })}
        </div>
      )}
    </div>
  );
};
