import React from 'react'
import { NavLink } from 'react-router-dom'
import { ImageUrl } from '../../constants/url/ImagePath'
function LiveProjects() {
    return (
        <div className=' flex flex-col gap-3 2xl:gap-8  md:ml-12 md:mr-10 2xl:mr-20'>
            <div className='w-full min-h-32 2xl:h-40 bg-white rounded-[15px]'>
                <div className='flex w-full gap-4 2xl:gap-8 p-6'>
                    <img src={ImageUrl.hema}  className='w-20 h-20 2xl:w-24 2xl:h-24 2xl:mt-3  object-cover rounded-full ' alt="hema" />
                    
                    <div className='2xl:mt-3 flex flex-col 2xl:gap-1'>
                        <p className='text-[#242424] not-italic font-bold text-[12px] md:text-[16px] 2xl:text-[20px]	'>
                            TTS  -  Speech and Music Technology
                        </p>
                        <p className='font-medium	not-italic md:text-[15.4px] text-[12px] 2xl:text-[20px] text-[#242424]'>You will work under <span className='text-[#CE5347]'>Prof. Hema Murthy</span> </p>
                        <p className='text-[#3C6FF4] font-medium	not-italic text-[10px] md:text-[15.4px] 2xl:text-[20px]'> <NavLink to="http://www.cse.iitm.ac.in/lab_details.php?arg=MTQ=" target="_blank">Speech and Music Technology (SMT Lab).</NavLink> </p>
                    
                    </div>
                </div>
            </div>
            <div className='w-full min-h-32 2xl:h-40 min-h-32 bg-white rounded-[15px]'>
                <div className='flex gap-4 2xl:gap-8 p-6'>
                <img src={ImageUrl.professorUmesh} className='w-20 h-20 2xl:w-24 2xl:h-24 2xl:mt-3 rounded-full object-fit' alt="umesh" />
                    <div className='2xl:mt-3 flex flex-col 2xl:gap-1'>
                        <p className='text-[#242424] not-italic font-bold text-[12px] md:text-[16px] 2xl:text-[20px]	'>
                            Automatic Speech Recognition
                        </p>
                        <p className='font-medium	not-italic text-[12px] md:text-[15.4px] 2xl:text-[20px] text-[#242424]'>You will work under <span className='text-[#CE5347]'>Prof. Umesh </span> </p>
                        <p className=' font-medium	not-italic text-[10px] md:text-[15.4px] 2xl:text-[20px]'>in <span className='text-[#3C6FF4]'><NavLink to="https://speech-lab-iitm.github.io/SpeechLab/" target="_blank">SPRING Lab.</NavLink> </span></p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LiveProjects