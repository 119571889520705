import React from 'react'
import authService from '../../service/authService'
import { AiOutlineClose } from 'react-icons/ai';
import { ImageUrl } from '../../constants/url/ImagePath';
import { useForm } from "react-hook-form";
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { getUserData, loginContext } from '../../Redux/Slice/UserSlice';
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai';

const LoginForm = (props) => {
    const [isShowPassword, setIsShowPassword] = useState(false);
    const redirectUrl = useSelector(state => state.user.redirectUrl)
    const showPassword = () => {
        setIsShowPassword(!isShowPassword);
    }


    const dispatch = useDispatch()

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const [loginMessage, setLoginMessage] = useState("")

    const submitLoginForm = (data) => {
        authService.login(data).then(data => {
            if (data?.status && data?.status === "Error credentials") {
                setLoginMessage("In correct Email or password")
                setTimeout(() => {
                    setLoginMessage("")
                }, 2000);
                return;
            }
            if (data?.token) {
                localStorage.setItem("token", JSON.stringify(data.token));
                let userAuth = authService.checkUserAlreadyLogin().email
                if (userAuth&&redirectUrl) {
                    dispatch(getUserData(userAuth));
                    authService.opendemoPage(redirectUrl)
                }
             
                dispatch(loginContext({ isshow: false, redirectUrl: "" }))
            }
        });

    }
    return (
        <form onSubmit={handleSubmit(submitLoginForm)}  >
            <div className="w-[500px] h-[500px]  flex flex-col justify-between p-8 rounded-lg  bg-[#ECF2FE]   shadow-xl transition-all">
                <div className='flex justify-end w-full px-2'>
                    <button type='button' onClick={() => dispatch(loginContext({ isshow: false, redirectUrl: "" }))}
                    ><AiOutlineClose /></button>
                </div>
                <div className='text-[25px]  relative font-semibold  text-[#030F30]'>
                    <div className='absolute w-20 h-10  left-[40%] bottom-[100%] flex justify-center items-center'>
                        <img src={ImageUrl.waveImage} height="100%" width="100%" alt="" />
                    </div>
                    SPRING Lab</div>
                <div className='flex flex-col w-full gap-y-2'>
                    <div className='relative flex flex-col w-full gap-y-3 ' >
                        <label htmlFor="email" className='text-[16px] font-medium text-start text-[#030F30]'>Email</label>
                        <input type="text" id="email" placeholder='Enter the email address' className='w-full text-[#030F30]  text-[13px] h-10 pl-2 bg-white rounded-lg outline-none'
                            {...register("email", {
                                required: "Please enter your email",
                                pattern: {
                                    value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                                    message: "Please enter a valid email"
                                }
                            })}
                        />
                        {errors.email && <div className="absolute text-red-600 text-[12px] font-medium top-[98%]">{errors.email.message}</div>}

                    </div>
                    <div className='relative flex flex-col w-full gap-y-1' >
                        <label htmlFor="Password" className='text-start text-[#030F30]'>Password</label>
                        <input type={isShowPassword ? "text" : "password"} name='password' id="password" placeholder='Enter the password' className='w-full text-[#030F30] text-[13px] h-10 pl-2 bg-white rounded-lg outline-none'
                            {...register("password", {
                                required: "Please enter the password",
                            })}
                        />
                        {isShowPassword ? <AiFillEye onClick={showPassword} size="20" className='absolute top-[55%] end-3 cursor-pointer' /> : <AiFillEyeInvisible onClick={showPassword} size="20" className='absolute top-[55%] end-3 cursor-pointer' />}
                        {errors.password && <div className="absolute text-red-600 text-[12px] font-medium top-[98%]">{errors.password.message}</div>}

                    </div>
                </div>
                <div className='w-full'>
                    <button type='submit' className={`w-full p-2 bg-[#030F30] text-base  not-italic border font-medium rounded-lg outline-none 
                    
                    ${loginMessage === "" ? "bg-[#030F30] text-white border-0" : "bg-white text-red-500 border-red-500"
                        }`}>{loginMessage === "" ? "Login" : loginMessage}</button>
                </div>
                <div className='w-full text-[14px] text-start'>
                    <span className='text-gray-600 '>Not Registered yet? </span><span className='text-[#030F30] not-italic font-medium cursor-pointer ' onClick={() => props.setIsRegisterForm(true)}>Create account</span></div>

            </div>
        </form>
    )
}

export default LoginForm