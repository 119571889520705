import React from 'react'

function OngoingProjects() {
  return (
    <div>
        <div className='w-full h-[415px] 2xl:h-[500px] bg-white rounded-[20px] p-5 md:p-8 2xl:p-10 overflow-y-scroll hideScrollbar '>
        <div className='text-[#2b3547] text-[24px] 2xl:text-[28px] font-normal'>
        Ongoing Projects
        </div>
        <div className='text-[#1D293F] font-bold text-[16px] 2xl:text-[20px] mt-2 2xl:mt-4'>
        National Language Translation Mission (NLTM) funded by MeitY
        </div>
        <p className='text-[#1D293F] font-normal not-italic text-[16px] 2xl:text-[20px] leading-6'>
        The Objective of this project is to develop Indian language technology systems and products and deploy them in the field with the help of Start-ups.
        </p>
        <div className='text-[#1D293F] font-bold text-[16px] 2xl:text-[20px] mt-3 2xl:mt-4' >
        Speech to Speech Machine Translation (SSMT) funded by PSA
        </div>
        <p className='text-[#1D293F] font-normal not-italic text-[16px] 2xl:text-[20px] leading-7'>
        The Objective of this project is Making Science and Engineering, as well as, other branches of knowledge available in Indian languages involving ASR, MT and TTS.
        </p>
        </div>
    </div>
  )
}

export default OngoingProjects