import React from "react";
import { useEffect } from "react";
import { useState } from "react";

export default function Pagination(props) {
    const [currentActivePageNumber, setCurrentActivePageNumber] = useState(1)
    const [currentPage, setCurrentPage] = useState("one");
    const [list, setList] = useState();
    // const [displayPageNumber, setDisplayPageNumber] = useState({
    //     one: null,
    //     two: null,
    //     three: null,
    //     four: null,
    //     five: null,
    // });

    // useEffect(() => { setPageNumber(); }, []);
    // const totalPages = () => {
    //     let totalPage;
    //     if (props.totalPage % 1 === 0) {
    //         totalPage = props.totalPage;
    //     } else {
    //         totalPage = parseInt(props.totalPage.toString().split(".")) + 1
    //     }
    //     return totalPage;
    // }

    const setPageNumber = () => {
        // setList(props.list);
        // let pages = [];
        // for (let index = 1; index <= totalPages(); index++) {
        //     pages.push(index);
        // }
        // setDisplayPageNumber({ one: pages[0], two: pages[1], three: pages[2], four: "...", five: pages[totalPages() - 1] })
    }

    // const getpage = (event, item) => {
    //     let data;
    //     switch (item) {
    //         case "previous":
    //             if (currentPage === "three") {
    //                 setCurrentPage("two");
    //                 data = sortPage(displayPageNumber.three - 1, item);
    //             }
    //             if (currentPage === "two") {
    //                 setCurrentPage("one")
    //                 data = sortPage(displayPageNumber.two - 1, item);

    //             }
    //             if (currentPage === "one") {
    //                 setCurrentPage("one")
    //                 if (displayPageNumber.one !== 1) {
    //                     setDisplayPageNumber({ previous: null, one: displayPageNumber.one - 1, two: displayPageNumber.two - 1, three: displayPageNumber.three - 1, four: "...", five: displayPageNumber.five, next: null });
    //                     data = sortPage(displayPageNumber.one - 1, item);
    //                 }
    //             }

    //             if (currentPage === "five") {
    //                 if (displayPageNumber.three >= displayPageNumber.five - 1) {
    //                     setCurrentPage("three")
    //                     data = sortPage(displayPageNumber.three, item);
    //                     setDisplayPageNumber({ one: displayPageNumber.one, two: displayPageNumber.two, three: displayPageNumber.three, four: "...", five: totalPages() })
    //                 } else
    //                     setDisplayPageNumber({ one: displayPageNumber.one, two: displayPageNumber.two, three: displayPageNumber.three, four: "...", five: displayPageNumber.five - 1 })
    //                 data = sortPage(displayPageNumber.five - 1, item);

    //             }
    //             break;
    //         case "one":
    //             setDisplayPageNumber({ one: displayPageNumber.one, two: displayPageNumber.two, three: displayPageNumber.three, four: "...", five: totalPages() });
    //             setCurrentPage("one");
    //             data = sortPage(displayPageNumber.one, item);
    //             break;
    //         case "two":
    //             setDisplayPageNumber({ one: displayPageNumber.one, two: displayPageNumber.two, three: displayPageNumber.three, four: "...", five: totalPages() });
    //             setCurrentPage("two");
    //             data = sortPage(displayPageNumber.two, item);
    //             break;
    //         case "three":
    //             setDisplayPageNumber({ one: displayPageNumber.one, two: displayPageNumber.two, three: displayPageNumber.three, four: "...", five: totalPages() });

    //             setCurrentPage("three");
    //             data = sortPage(displayPageNumber.three, item);
    //             break;
    //         case "five":
    //             setCurrentPage("five");
    //             data = sortPage(displayPageNumber.five, item);
    //             break;

    //         case "next":
    //             if (currentPage === "one") {
    //                 setCurrentPage("two")
    //                 data = sortPage(displayPageNumber.one + 1, item);

    //             }
    //             if (currentPage === "two") {
    //                 setCurrentPage("three")
    //                 data = sortPage(displayPageNumber.two + 1, item);

    //             }
    //             if (currentPage === "three") {
    //                 setCurrentPage("three")
    //                 if (displayPageNumber.five !== displayPageNumber.three) {
    //                     setDisplayPageNumber({ previous: null, one: displayPageNumber.one + 1, two: displayPageNumber.two + 1, three: displayPageNumber.three + 1, four: "...", five: displayPageNumber.five, next: null });
    //                     data = sortPage(displayPageNumber.three + 1, item);
    //                 }

    //             }
    //             if (currentPage === "five") {
    //                 if (displayPageNumber.five !== totalPages()) {

    //                     setDisplayPageNumber({ one: displayPageNumber.one, two: displayPageNumber.two, three: displayPageNumber.three, four: "...", five: displayPageNumber.five + 1 })
    //                     data = sortPage(displayPageNumber.five + 1, item);
    //                 }

    //             }
    //             break;
    //         default:
    //             data = [];

    //     }
    //     props.callback(data);
    // }

    // const sortPage = (number, item) => {
    //     setCurrentActivePageNumber(number);
    //     let finalNumber = number * 10;
    // let data = [];
    // let pageSize = finalNumber -10;
    //   list.forEach((element, i) => {
    //             if (i > pageSize && i <= finalNumber) {
    //                 data.push(element);
    //             }
    //         });
    // return data;

    // }



    // return (
    //     <div className="w-full flex justify-center md:justify-end ">
    //         <div className="flex gap-x-3">
    //             <button onClick={event => getpage(event, "previous")} className={"h-10 w-10 md:h-11 md:w-11 flex justify-center rounded-lg items-center text-[#1E66AE] font-normal text-[18px] bg-[#F8F8F8]"}
    //             >
    //                 <svg width="48" height="47" viewBox="0 0 48 47" fill="none" xmlns="http://www.w3.org/2000/svg">
    //                     <rect fill="#F5F5FA" x="0.25" width="47" height="47" rx="5.875" />
    //                     <path d="M21.1086 29.6592V29.6592C21.4905 29.2773 21.4905 28.6604 21.1086 28.2785L17.3095 24.4794H32.1634C32.702 24.4794 33.1426 24.0387 33.1426 23.5002V23.5002C33.1426 22.9617 32.702 22.521 32.1634 22.521H17.2997L21.0988 18.7219C21.4807 18.34 21.4807 17.7231 21.0988 17.3412V17.3412C20.717 16.9594 20.1001 16.9594 19.7182 17.3412L14.2447 22.8148C13.8628 23.1967 13.8628 23.8135 14.2447 24.1954L19.728 29.6592C20.1001 30.041 20.7267 30.041 21.1086 29.6592Z"
    //                         fill={displayPageNumber.one === 1 && currentPage === "one" ? "#CCD3DC" : "#1E66AE"} />
    //                 </svg>
    //             </button>

    //             <button onClick={event => getpage(event, "one")} className=" h-10 w-10 md:h-11 md:w-11 bg-[#F8F8F8] flex justify-center rounded-lg items-center font-normal text-[18px] "
    //                 style={{ background: currentPage === "one" ? "#1E66AE" : "#F8F8F8", color: currentPage === "one" ? "white" : "#1E66AE" }}
    //             >
    //                 <div>{displayPageNumber.one}</div>
    //             </button>
    //             <button onClick={event => getpage(event, "two")} className="h-10 w-10 md:h-11 md:w-11 flex justify-center items-center rounded-lg text-[#1E66AE] font-normal text-[18px]"
    //                 style={{ background: currentPage === "two" ? "#1E66AE" : "#F8F8F8", color: currentPage === "two" ? "white" : "#1E66AE" }}
    //             >
    //                 <div>{displayPageNumber.two}</div>
    //             </button>
    //             <button onClick={event => getpage(event, "three")} className="h-10 w-10 md:h-11 md:w-11 bg-[#F8F8F8] flex justify-center rounded-lg items-center text-[#1E66AE] font-normal text-[18px]"
    //                 style={{ background: currentPage === "three" ? "#1E66AE" : "#F8F8F8", color: currentPage === "three" ? "white" : "#1E66AE" }}

    //             >
    //                 <div>{displayPageNumber.three}</div>
    //             </button>
    //             <button onClick={event => getpage(event, "four")} className="h-10 w-10 md:h-11 md:w-11 bg-[#F8F8F8] flex justify-center rounded-lg items-center text-[#1E66AE] font-normal text-[18px]"
    //                 style={{ display: displayPageNumber.three === totalPages() ? "none" : "" }}
    //             >
    //                 <div>{displayPageNumber.four}</div>
    //             </button>
    //             <button onClick={event => getpage(event, "five")} className="h-10 w-10 md:h-11 md:w-11 bg-[#F8F8F8] flex justify-center rounded-lg items-center text-[#1E66AE] font-normal text-[18px]"
    //                 style={{ background: currentPage === "five" ? "#1E66AE" : "#F8F8F8", color: currentPage === "five" ? "white" : "#1E66AE", display: displayPageNumber.three === totalPages() ? "none" : "" }}
    //             >
    //                 <div>{displayPageNumber.five}</div>
    //             </button>
    //             <button onClick={event => getpage(event, "next")} className="h-10 w-10 md:h-11 md:w-11 bg-[#F8F8F8] flex justify-center rounded-lg items-center text-[#1E66AE] font-normal text-[18px]"
    //             >

    //                 <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    //                     <path d="M14.7429 6.34085V6.34085C14.3611 6.72272 14.3611 7.3396 14.7429 7.72147L18.5421 11.5206H3.68815C3.14961 11.5206 2.70898 11.9613 2.70898 12.4998V12.4998C2.70898 13.0383 3.14961 13.479 3.68815 13.479H18.5519L14.7527 17.2781C14.3709 17.66 14.3709 18.2769 14.7527 18.6588V18.6588C15.1346 19.0406 15.7515 19.0406 16.1334 18.6588L21.6069 13.1852C21.9888 12.8033 21.9888 12.1865 21.6069 11.8046L16.1236 6.34085C15.7515 5.95897 15.1248 5.95897 14.7429 6.34085Z"
    //                         fill={currentActivePageNumber === totalPages() ? "#CCD3DC" : "#1E66AE"}
    //                     />
    //                 </svg>

    //             </button>

    //         </div>
    //     </div>
    // )
}
