import React from "react";
import "../../Style/RecentPublication.css";
import { NavLink } from "react-router-dom";
function RecentPublications() {

  const handleClickMenu = () => {
    window.scrollTo(0, 0);
  };

  let recentPublications = require("../../constants/json/RecentPublication.json");
  return (
    <div className=" xl:pt-40  2xl:mt-80">
      <div className="bgRecentColor md:pt-16 flex flex-col gap-8 sm:px-5 md:px-20 pt-14">
        <p className="text-center publicationText text-sm-recent_head sm:text-md-1  md:text-md-1 lg:text-lg-1 xl:text-xl-recent_head 2xl:text-2xl-recent_head">
          Recent
          <span className="font-bold text-3xl  publicationText ml-2">
            News
          </span>{" "}
          and
          <span className="font-bold 2xl:text-5xl publicationText">
            {" "}
            Publications
          </span>
        </p>
        <div className="grid grid-cols-1 px-4 md:px-0 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 place-items-center gap-6 pt-3 2xl:pt-6 ">
          {recentPublications.map((publication, index) => {
            return (
              <div key={index} className="h-64 sm:h-70 w-full md:h-60 xl:h-64 2xl:h-96 rounded-2xl bg-white flex flex-col gap-2 p-5 2xl:p-7 recentPublixcationCard ">
                <div className="not-italic font-light xl:text-xl-recent_cardText 2xl:text-2xl-recent_cardText leading-5 recentpublicationcardText 2xl:h-60">
                  {publication.cardText}
                </div>
                <div className=" flex flex-col gap-1 xl:mt-8 appearDiv mt-6 md:mt-4">
                  <p className="not-italic font-semibold xl:text-xl-appearText 2xl:text-2xl-appearText appearText">
                   {publication.appearText}
                  </p>
                  <p className="not-italic font-normal xl:text profileName">
                   {publication.footerName}
                  </p>
                </div>
              </div>
            );
          })}

        </div>
        <div className="flex justify-center pt-6 pb-10 xl:pt-2 2xl:pb-14">
        <NavLink to="/publications"> 
          <button onClick={handleClickMenu} className="w-28 h-10 md:w-28 md:h-10 lg:w-36 lg:h-12 xl:w-48 xl:h-14 2xl:w-56 2xl:h-16 3xl:w-64 3xl:h-18 rounded-full text-white font-bold text-sm-learnMoreBtn lg:text-lg-learnMoreBtn xl:text-xl-learnMoreBtn  2xl:text-2xl-learnMoreBtn not-italic learnMoreBtn">
            Read More
          </button>
          </NavLink>
        </div>
      </div>
    </div>
  );
}

export default RecentPublications;
