import React from 'react'
import TableLink from '../../../../TableLink';


const initialTableData = require("../../../../../constants/demo1/model/initialtable.json")


const InitialTable = () => {

    return (
        <div className="w-full  rounded-lg bg-[#FFF] tableAlignment">


            <table className="w-full ">
                <thead
                    className="w-full text-[#363636] text-[16px] font-normal not-italic bg-gray-100 tableAlignment ">
                    <tr>
                        <th>
                            <div className="flex items-center justify-center gap-x-2">
                                <div>Models</div>

                            </div>
                        </th>
                        <th>
                            <div className="flex items-center justify-center gap-x-2">
                                <div> data2vec_aqc</div>

                            </div>
                        </th>
                        <th>
                            <div className="flex items-center justify-center gap-x-2">
                                <div> ccc_wav2vec</div>

                            </div>
                        </th>
                        <th>
                            <div className="flex items-center justify-center gap-x-2">
                                <div> data2vec</div>

                            </div>
                        </th>
                        <th>
                            <div className="flex items-center justify-center gap-x-2">
                                <div> wav2vec</div>

                            </div>
                        </th>
                        <th>
                            <div className="flex items-center justify-center gap-x-2">
                                <div>hubert</div>

                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody className="text-[#363636] font-normal text-sm not-italic text-center ">

                    {initialTableData?.map((item, i) => {
                        return (
                            <tr key={i} className='text-blue-600 cursor-pointer ' >
                                <td className='text-[#030F30] pointer-events-none' >{item.model}</td>
                                <td>
                                    <TableLink name={item.data2vec_aqc.name} link={item.data2vec_aqc.link} />
                                </td>
                                <td>
                                    <TableLink name={item.ccc_wav2vec.name} link={item.ccc_wav2vec.link} />
                                </td>
                                <td>
                                    <TableLink name={item.data2vec.name} link={item.data2vec.link} />
                                </td>
                                <td>
                                    <TableLink name={item.wav2vec.name} link={item.wav2vec.link} />
                                </td>
                                <td>
                                    <TableLink name={item.hubert.name} link={item.hubert.link} />

                                </td>

                            </tr>
                        )

                    })}

                </tbody>
            </table>
        </div>
    )
}

export default InitialTable

{/* <td className='text-blue-500 cursor-pointer'>{data.data2vec_aqc.name}</td> */ }