import React from "react";
import { Helmet } from "react-helmet";
import AluminiContainer from "../component/Alumini/AluminiContainer";
import AluminiHeader from "../component/Alumini/AluminiHeader";

export default function Alumni() {
    return (
        <div>
            <Helmet>
                <title>Spring Lab, IIT Madras - Alumni</title>
            </Helmet>
            <AluminiHeader />
            <AluminiContainer/>
        </div>
    )
}
