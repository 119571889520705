import { createSlice } from '@reduxjs/toolkit'





export const userSlice = createSlice({
    name: 'user',
    initialState:{
        user:"",
        isshow: false,
        redirectUrl:""
    },
    reducers: {

        getUserData: (state, action) => {
            state.user = action.payload
        },
        loginContext: (state, action) => {
            state.isshow=action.payload.isshow;
            state.redirectUrl=action.payload.redirectUrl;
        },
    },
})

// Action creators are generated for each case reducer function
export const { getUserData,loginContext } = userSlice.actions

export default userSlice.reducer