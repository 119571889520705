import React from 'react'
import { ImageUrl } from '../../constants/url/ImagePath'
import '../../Style/Map.css'
import { NavLink } from 'react-router-dom'


function Map() {
    return (
        <div className='w-full md:ml-12'>
            <div className='font-bold not-italic findUsText lg:text-lg-mapHead xl:text-xl-mapHead 2xl:text-2xl-mapHead'>
                WHERE TO FIND US
            </div>

            <div className=' h-[250px] 2xl:h-[350px] rounded-3xl mt-6 flex justify-center md:justify-start '>
                <img src={ImageUrl.IITmap} alt="" className='w-full md:w-[80%] 2xl:w-[70%] h-full  rounded-3xl object-cover' />
            </div>
            <div className=' mt-6 font-bold not-italic lg:text-lg-mapHead xl:text-xl-mapHead 2xl:text-2xl-mapHead findUsText'>
                CONTACTS
            </div>
            <div className='mt-3 w-full sm:w-80  md:ml-0  font-normal not-italic lg:text-lg-mapContact xl:text-xl-mapContact 2xl:text-2xl-mapContact mapAddress'>
                Room No. CSD-313, ESB Building,
                Dept of Electrical Engineering,
                Indian Institute of Technology - Madras,
                Chennai 600 036
                {/* Tel: 044 2257-5477 */}
            </div>

            <div className='mt-5'>
                <span className='not-italic	lg:text-lg-mapContact xl:text-xl-mapContact 2xl:text-2xl-mapContact mapEmail'>
                    <NavLink to="mailto:speechiitm@ee.iitm.ac.in">speechiitm@ee.iitm.ac.in </NavLink>
                    <span className='text-[gray]'></span> 
                    </span>             
                    {/* <NavLink to="mailto:speechiitm@gmail.com"> speechiitm@gmail.com</NavLink></span> */}
            </div>

        </div>
    )
}

export default Map