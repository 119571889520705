import React from 'react'
import ProjectHeader from '../component/Projects/ProjectHeader'
import AvailableProjects from '../component/Projects/AvailableProjects'
import PastAndGoingProjects from '../component/Projects/PastAndGoingProjects'
import { Helmet } from 'react-helmet'
function Projects() {
    return (
        <div>
             <Helmet>
                <title>Spring Lab, IIT Madras - Hiring</title>
            </Helmet>
            <ProjectHeader />
            <div className='px-4 md:px-14 '>
                <AvailableProjects />
            </div>

            <div className='bg-[#ECF2FE]'>
            <PastAndGoingProjects/> 
            </div>
        </div>
    )
}

export default Projects