import React from "react";
import "../../Style/Footer.css";
import { ImageUrl } from "../../constants/url/ImagePath";
import { NavLink } from "react-router-dom";
import { Homedata } from "../../constants/data/HomePageData";
import { HomeUrl } from "../../constants/url/HomePageUrl";
import { useState } from "react";
import authService from "../../service/authService";
import LoginPoup from "./LoginPoup";
import { useDispatch, useSelector } from "react-redux";
import { loginContext } from "../../Redux/Slice/UserSlice";
import { VscGithubInverted } from "react-icons/vsc";

const footerMenu = [
  { menus: "Home", navigation: "/" },
  { menus: "Hiring", navigation: "/hiring" },
  { menus: "Publications", navigation: "/publications" },
  {
    menus: "Github",
    navigation: "https://github.com/Speech-Lab-IITM",
    target: "_blank",
  },
  { menus: "Alumni", navigation: "/alumni" },
  { menus: "Gallery", navigation: "/gallery" },
  {
    menus: "Demo",
    navigation: "https://asr.iitm.ac.in/demo/",
    target: "_blank",
  },
  {
    menus: "Resources",
    navigation: "https://speech-lab-iitm.github.io/docs/",
    target: "_blank",
  },
  { menus: "Contact Us", navigation: "/contact" },
];

// const footerMenu = [
//   { menus: "Home", navigation: "/" },
//   { menus: "Hiring", navigation: "/hiring" },
//   { menus: "Publications", navigation: "/publications" },
//   {
//     menus: "Github",
//     navigation: "https://github.com/Speech-Lab-IITM",
//     target: "_blank",
//   },
//   { menus: "Alumni", navigation: "/alumni" },
//   { menus: "Gallery", navigation: "/gallery" },
//   // { menus: "Demo", navigation: "https://asr.iitm.ac.in/demo/", target: "_blank", },
//   { menus: "Resources", navigation: "https://speech-lab-iitm.github.io/docs/", target: "_blank" },
//   { menus: "Contact Us", navigation: "/contact" }
// ];

function Footer() {
  const dispatch = useDispatch();
  let date = new Date();
  let year = date.getFullYear();

  const handleClick = (url) => {
    if (authService.checkUserAlreadyLogin()) {
      authService.opendemoPage(url);

      return;
    }
    dispatch(
      loginContext({
        isshow: true,
        redirectUrl: url,
      })
    );
  };
  const handleClickMenu = () => {
    window.scrollTo(0, 0);
  };

  let contact = require("../../constants/json/Address.json");
  let downloads = require("../../constants/json/FooterDownloads.json");
  return (
    <div className="pb-6 mt-12 footerBgColor">
      <LoginPoup />
      <div className="grid grid-cols-1 gap-10 px-6 text-white md:px-40 pt-14 md:grid-cols-2 xl:grid-cols-5">
        <div>
          <div className="flex not-italic font-medium lg:text-lg-speechLabHead xl:text-xl-speechLabHead 2xl:text-2xl-speechLabHead">
            SPRING Lab
            <div>
              <img
                src={ImageUrl.blueLeaf}
                alt="blueLeaf"
                className="relative right-2 bottom-1"
              />
            </div>
            <div>
              <img src={ImageUrl.redLeaf} alt="" className="relative right-1" />
            </div>
          </div>
          <div className="pt-3 footerText lg:text-lg-speechLabText xl:text-xl-speechLabText 2xl:text-2xl-speechLabText 2xl:pt-8 md:w-60 min-h-1">
            {Homedata.footerDescription}{" "}
          </div>
          <div className="pt-5 2xl:pt-8">
            <NavLink to="contact">
              <button
                onClick={handleClickMenu}
                className="w-24 not-italic font-normal rounded-md messageUsBtn text-sm-learnMoreBtn lg:28 lg:h-8 xl:h-11 xl:w-28 2xl:w-32 2xl:h-12 lg:text-lg-messageusText xl:text-xl-messageusText 2xl:text-2xl-messageusText "
              >
                Message Us
              </button>
            </NavLink>
          </div>
        </div>

        <div className="md:ml-20">
          <div className="not-italic font-light footerText lg:text-lg-headerMenu xl:text-xl-headerMenu 2xl:text-2xl-headerMenu">
            Navigations
          </div>
          <div className="flex flex-col gap-5 pt-8 not-italic font-normal 2xl:pt-12 lg:text-lg-speechLabText xl:text-xl-speechLabText 2xl:text-2xl-speechLabText">
            {footerMenu.map((menu, index) => {
              return (
                <NavLink
                  key={index}
                  to={menu.navigation}
                  target={menu.target}
                  onClick={
                    menu.menus === "Demo"
                      ? () => handleClick(HomeUrl.demoUrl)
                      : menu.target == null
                      ? handleClickMenu
                      : null
                  }
                >
                  <div>{menu.menus}</div>
                </NavLink>
              );
            })}
          </div>
        </div>

        <div className="md:pl-8 flex flex-col gap-3 not-italic font-normal lg:text-lg-speechLabText xl:text-xl-speechLabText 2xl:text-2xl-speechLabText">
          {" "}
          <div className="not-italic font-light footerText lg:text-lg-headerMenu xl:text-xl-headerMenu 2xl:text-2xl-headerMenu">
            Products
          </div>
          <div className="flex flex-col gap-3 pt-8 not-italic font-normal 2xl:pt-12 lg:text-lg-speechLabText xl:text-xl-speechLabText 2xl:text-2xl-speechLabText ">
            <div
              onClick={() => handleClick(HomeUrl.speechUrl)}
              className="cursor-pointer"
            >
              Speech to Speech
            </div>
            <div
              onClick={() => handleClick(HomeUrl.asrUrl)}
              className="cursor-pointer"
            >
              ASR 2.0
            </div>
            <div
              onClick={() => handleClick(HomeUrl.videoUrl)}
              className="cursor-pointer"
            >
              Video to Video
            </div>

            <div
              onClick={() => handleClick(HomeUrl.tts)}
              className="cursor-pointer"
            >
              TTS
            </div>
            <div
              onClick={() => handleClick(HomeUrl.ttt)}
              className="cursor-pointer"
            >
              TTT
            </div>
            <div
              onClick={() => handleClick(HomeUrl.prosody)}
              className="cursor-pointer"
            >
              Prosody
            </div>
          </div>
        </div>
        <div>
          <div className="not-italic font-light footerText lg:text-lg-headerMenu xl:text-xl-headerMenu 2xl:text-2xl-headerMenu">
            Downloads
          </div>
          <div className="flex flex-col gap-3 pt-8 not-italic font-normal 2xl:pt-12 lg:text-lg-speechLabText xl:text-xl-speechLabText 2xl:text-2xl-speechLabText ">
            {downloads?.map((data) => {
              return (
                <NavLink
                  key={data?.id}
                  to={data?.redirect}
                  onClick={() => window.scrollTo(0, 0)}
                >
                  <div className="cursor-pointer">{data?.name}</div>
                </NavLink>
              );
            })}
          </div>
        </div>

        <div className="md:ml-20 xl:ml-0">
          <div className="not-italic font-light footerText lg:text-lg-headerMenu xl:text-xl-headerMenu 2xl:text-2xl-headerMenu">
            Contacts
          </div>
          {contact.map((contacts, index) => {
            return (
              <div
                key={index}
                className="pt-8 2xl:pt-12 md:w-[18rem]  2xl:w-full flex flex-col gap-3 not-italic font-normal lg:text-lg-speechLabText xl:text-xl-speechLabText 2xl:text-2xl-speechLabText"
              >
                {contacts.address}
                <br />
                {contacts.department}
                <br />
                {contacts.collage}
                <br />
                {contacts.pin}
                <br />
                {contacts.phone}
              </div>
            );
          })}
          <div className="flex flex-col pt-3 gap-y-2">
            <div className="email text-[15px] lg:text-lg-email xl:text-xl-email 2xl:text-2xl-email">
              {/* ${ Homedata.iitMailid} */}
              {/* speechiitm@ee.iitm.ac.in */}
              <NavLink to="mailto:speechiitm@ee.iitm.ac.in">
                speechiitm@ee.iitm.ac.in
              </NavLink>
            </div>
            <div className="email text-[15px] lg:text-lg-email xl:text-xl-email 2xl:text-2xl-email ">
              {/* {Homedata.gmailid} */}
              {/* <NavLink to="mailto:speechiitm@gmail.com"> speechiitm@gmail.com</NavLink> */}
            </div>
            <div className="email flex items-center gap-x-2 text-sm lg:text-lg xl:text-xl 2xl:text-2xl">
  <NavLink
    to="https://www.linkedin.com/company/spring-lab-iit-madras/"
    target="_blank"
    title="LinkedIn"
  >
    <img
      src={ImageUrl.linkedInNew}
      className="w-6 lg:w-8 xl:w-10 2xl:w-12 cursor-pointer"
      alt="LinkedIn Icon"
    />
  </NavLink>
  <NavLink to="https://github.com/Speech-Lab-IITM" target="_blank">
    <VscGithubInverted
      title="GitHub"
      className="github-icon cursor-pointer"
    />
  </NavLink>
  <NavLink to="https://huggingface.co/SPRINGLab" target="_blank">
    <img
      src={ImageUrl.huggingFace}
      className="w-6 lg:w-8 xl:w-10 2xl:w-12 cursor-pointer"
      alt="Hugging Face Icon"
      title="Hugging Face"
    />
  </NavLink>
</div>

          </div>
        </div>
      </div>
      <div className="pt-16 md:px-24">
        <div className="horizontalLine xl:mx-16 2xl:mx-16 "></div>
        <div className="px-16 pt-5 text-center footerCopyRight md:text-start lg:text-lg-copyRight xl:text-xl-copyRight 2xl:text-2xl-copyRight">
          © {year} SPRING Lab, IIT Madras - All rights reserved
        </div>
      </div>
    </div>
  );
}

export default Footer;
