import React from 'react'
import { NavLink } from 'react-router-dom'

function ProjectsAndInternPosition() {
    return (
        <div className='flex flex-col gap-[8px] pb-7 2xl:pb-16 '>
            <div className='text-[#CE5347] not-italic font-normal text-[21px] md:text-[24px] 2xl:text-[28px]' >
            Stay Tuned for
            </div>
            <div className='text-[#242424] text-[20px] md:text-[32px] 2xl:text-[36px] font-bold not-italic	'>
             Upcoming Opportunities in <br /> Automatic Speech Recognition. <br />
            </div>
            <div className='text-[#1D293F] not-italic font-normal md:text-[18px] 2xl:text-[20px] leading-8 2xl:leading-10
'>
                You will build state-of-the-art ASR and TTS systems using <br />
                deep learning ideas such as sequence  -to-sequence <br /> modelling
                based on CTC, Transformers, BERT & GPT as well as  <br /> conventional
                DNN, CNN and TDNN. Applicants who have <br /> completed B.E./B.Tech./M.Tech are
                encouraged to apply via <br /> e-mail to <span className='text-[#3C6FF4]'> <NavLink to="mailto:speechiitm@ee.iitm.ac.in">speechiitm@ee.iitm.ac.in</NavLink> </span>
            </div>
        </div>
    )
}

export default ProjectsAndInternPosition