import { useState, useEffect } from "react";
import { ImageUrl } from "../../constants/url/ImagePath";
import PublicationList from "./PublicationList";
import Pagination from "../Shared/Pagination";
import ReactPaginate from 'react-paginate';
export default function PublicationType(props) {
    const [loadingData, setLoadingData] = useState([]);
    const [toogleDropDown, setToogleDropDown] = useState(false);

    const getpageData = (value) => {
        if (value != null) {
            setLoadingData(value)
        }
    }

    useEffect(() => {
        if (props.publicationList.id === 1) {
            setToogleDropDown(true);
        }
    }, [])

    function Items({ currentItems }) {
        return (
            <>
                {currentItems &&
                    currentItems.map((val,i) => (
                        <div key={i}>
                            <PublicationList author={val.author} topic={val.topic} key={val.topic} />
                        </div>
                    ))}
            </>
        );
    }
    function PaginatedItems({ itemsPerPage, list }) {
        const [itemOffset, setItemOffset] = useState(0);
        const endOffset = itemOffset + itemsPerPage;
        const currentItems = list.slice(itemOffset, endOffset);
        const pageCount = Math.ceil(list.length / itemsPerPage);

        const handlePageClick = (event) => {
            const newOffset = (event.selected * itemsPerPage) % list.length;

            setItemOffset(newOffset);
        };
        return (
            <>
                <Items currentItems={currentItems} />
                <ReactPaginate
                    breakLabel="..."
                    nextLabel=">"
                    pageRangeDisplayed={''}
                    pageCount={pageCount}
                    onPageChange={handlePageClick}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                    containerClassName={"paginationBttns"}
                    nextLinkClassName={"nextBttn"}
                    activeClassName={"paginationActive"}
                    pageLinkClassName="page-num"
                    activeLinkClassName="active"
                    disabledClassName={'disabled-page-link'}
                    previousClassName={"prevDisabled"}
                    nextClassName={"nextDisabled"}


                />
            </>
        );
    }


    return (
        <div className="w-full flex flex-col gap-y-4">

            <div key={props.publicationList.id} className="w-full flex flex-col gap-y-3 ">
                <div className="w-full text-[#CE5347] px-8 py-6 bg-[#ECF2FE] cursor-pointer rounded-lg font-extralight text-[22px]  flex justify-between"
                    onClick={() => toogleDropDown ? setToogleDropDown(false) : setToogleDropDown(true)} >
                    <div> {props.publicationList.option}</div>
                    <img src={ImageUrl.selectBoxArrow} className={toogleDropDown ? "" : "rotate-180"} height="16" width="16" alt="arrow" />
                </div>
                <div className="w-full flex flex-col gap-y-1  " style={{ display: toogleDropDown ? "" : "none" }}  >
                    <PaginatedItems itemsPerPage={10} list={props.publicationList.list} />
                </div>
                <div className="w-full" style={{ display: toogleDropDown ? "" : "none" }}  >
                    {props.publicationList.list.length > 10 ? <Pagination  totalPage={props.publicationList.list.length / 10} list={props.publicationList.list} callback={getpageData} key={props.publicationList.id} /> : null}
                </div>
            </div>
        </div>
    )
}
