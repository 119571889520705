import React from 'react'
import '../../Style/Contact.css'
function Contact() {
    return (
        <div className='w-full lg:ml-12'>
            <div className='flex flex-col gap-7'>
                <input type="text" placeholder='First Name' className='w-full h-12  outline-none  not-italic font-normal lg:text-lg-placeholder xl:text-xl-placeholder 2xl:text-2xl-placeholder pl-4' />
                <input type="text" placeholder='E-mail' className=' w-full h-12 outline-none  not-italic font-normal lg:text-lg-placeholder xl:text-xl-placeholder 2xl:text-2xl-placeholder pl-4 ' />
                <textarea placeholder='Text your message here..' className=' textAreaSize w-full h-12 outline-none not-italic font-normal lg:text-lg-placeholder xl:text-xl-placeholder 2xl:text-2xl-placeholder pl-4 pt-4' id="" cols="30" rows="10"></textarea>
            </div>
            <div className='pt-4'>
                <p className='lg:text-lg-mapHead xl:text-xl-mapHead 2xl:text-2xl-mapHead not-italic attachDocument font-bold underline	'>Attach documents</p>
            </div>
            <div className='flex justify-center pt-3 md:pt-0 md:justify-end md:mr-10 '>
                <button className=' w-28 h-10 md:w-36 md:h-12 xl:w-36 xl:h-12 2xl:w-44 2xl:h-16 rounded-full text-white lg:text-lg-submitBtn xl:text-xl-submitBtn 2xl:text-2xl-submitBtn font-bold not-italic	learnMoreBtn'>Submit</button>
            </div>
        </div>
    )
}

export default Contact