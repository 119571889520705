import React from 'react'
import TableLink from '../../../../TableLink'
import "../../Body.css"


const table3Json = require("../../../../../constants/demo1/model/table3.json")

export const Thirdtable = () => {

    return (
        <div className="w-full  rounded-lg bg-[#FFF] tableAlignment">
            <table className="w-full ">
                <thead
                    className="w-full text-[#363636] text-[16px] font-normal not-italic bg-gray-100 tableAlignment ">
                    <tr>
                        <th>
                            <div className="flex items-center justify-center gap-x-2">
                                <div>Models</div>

                            </div>
                        </th>
                        <th>
                            <div className="flex items-center justify-center gap-x-2">
                                <div>Pretrained</div>

                            </div>
                        </th>
                        <th>
                            <div className="flex items-center justify-center gap-x-2">
                                <div> Finetuned</div>

                            </div>
                        </th>


                    </tr>
                </thead>
                <tbody className="text-[#363636] font-normal text-sm not-italic text-center ">
                    {table3Json?.map(item => {
                        return <tr key={item.model}  className='cursor-pointer '>
                            <td className='text-[#030F30] pointer-events-none'>
                                {item.model}</td>
                            <td>
                                <TableLink name={item.Pretrained.name} link={item.Pretrained.link} />

                            </td>
                            <td>

                                <TableLink name={item.Finetuned.name} link={item.Finetuned.link} />

                            </td>
                        </tr>
                    })
                    }


                </tbody>
            </table>
        </div>
    )
}
