import React, { useRef } from 'react'
import ImageGallery from './ImageGallery'
function GalleryNav() {
    const navTab = useRef('')
    // const navBarHeader = [
    //     {
    //         navName: "All", navigation: ""
    //     }

    //     ,
    //     {
    //         navName: "ASR", navigation: ""
    //     },
    //     {
    //         navName: "TTS", navigation: ""
    //     },
    //     {
    //         navName: "Team", navigation: ""
    //     },
    // ]

    const handleNavTab = (e) => {

        console.log("ewwdf", e.target.innerText);
        let innerText = e.target.innerText
        if (innerText === 'All') {
            console.log("all", navTab);
            return
        }
        if (innerText === 'ASR') {
            console.log("asr");
            return

        }


    }
    return (

        <div className=' md:px-28'>
            {/* <div className='px-32 flex gap-16 justify-center mt-16'>
                <div ref={navTab} className={` min-w-[50px] hidden md:flex flex-col text-center justify-between text-[16px] 2xl:text-[20px] h-14  font-medium  text-[#1875F0]`} onClick={handleNavTab}>
                    <div>
                        All
                    </div>
                    <div className=' h-[2px] bg-blue-700'>

                    </div>

                </div>
             
            </div> */}
            <div className='border border-l-0 border-r-0 px-4 '>
                <ImageGallery />
            </div>
        </div>
    )
}

export default GalleryNav