import React from "react";
import "../../Style/Header.css";
import { NavLink } from "react-router-dom";
import { ImageUrl } from "../../constants/url/ImagePath";
import { useState } from "react";
import authService from "../../service/authService";
import { useEffect } from "react";
import { NavMenu } from "./NavMenu";
import { useSelector, useDispatch } from "react-redux";
import { getUserData, loginContext } from "../../Redux/Slice/UserSlice";
import { BiSolidUserCircle, BiLogOut } from "react-icons/bi";
import { HomeUrl } from "../../constants/url/HomePageUrl";

function Navbar() {
  const dispatch = useDispatch();
  const [hideShowCloseBtn, setHideShowCloseBtn] = useState(false);
  const { user } = useSelector((state) => state.user);
  const [isHoverUserIcon, setIsHoverUserIcon] = useState(false);

  useEffect(() => {
    let credential = authService.checkUserAlreadyLogin();
    if (credential) {
      let email = extractLettersBeforeAt(credential.email);
      dispatch(getUserData(email));
    }
  });
  function extractLettersBeforeAt(email) {
    // Use a regular expression to match letters before '@'
    const matches = email.match(/^[a-zA-Z]+/);

    // Check if there is a match
    if (matches) {
      return matches[0];
    } else {
      return null; // Return null if no match is found
    }
  }

  const mobileMenu = [
    { name: "Home", navigation: "/", target: "" },
    {
      name: "Dataset",
      navigation: "/dataset",
      traget: "",
    },
    {
      name: "Models",
      navigation: "/models",
      traget: "",
    },
    { name: "Publications", navigation: "/publications", target: "" },
    { name: "Projects", navigation: "/project", target: "" },
    { name: "Gallery", navigation: "/gallery", target: "" },
    { name: "Alumni", navigation: "/alumni", target: "" },
    { name: "Employees", navigation: "/employees", target: "" },

    {
      name: "GitHub",
      navigation: "https://speech-lab-iitm.github.io/docs/",
      target: "_blank",
    },
    {
      name: "API Ref",
      navigation: "https://speech-lab-iitm.github.io/docs/api_docs/asr/",
      target: "_blank",
    },
  ];

  const navbarMenu = [
    { name: "Home", navigation: "/", target: "" },
    {
      name: "Dataset",
      navigation: "/dataset",
      traget: "",
    },
    {
      name: "Models",
      navigation: "/models",
      traget: "",
    },
    {
      name: "Demo",
      navigation: HomeUrl.demoUrl,
      traget: "",
    },
    { name: "Publications", navigation: "/publications", target: "" },
    { name: "Projects", navigation: "/project", target: "" },

    {
      name: "Resources",

      target: "",
      children: [
        {
          name: "GitHub",
          navigation: "https://github.com/Speech-Lab-IITM",
          target: "_blank",
        },
        {
          name: "API Ref",
          navigation: "https://speech-lab-iitm.github.io/docs/api_docs/asr/",
          target: "_blank",
        },
      ],
    },
    {
      name: "People",
      navigation: "",
      target: "",
      children: [
        { name: "Employees", navigation: "/employees", target: "" },
        { name: "Alumni", navigation: "/alumni", target: "" },
        { name: "Gallery", navigation: "/gallery", target: "" },
      ],
    },
    // { name: "Forum", navigation: "", target: "" }
  ];

  const handleCloseMenu = () => {
    setHideShowCloseBtn(false);
  };

  const handleHideShowMenu = () => {
    setHideShowCloseBtn(true);
  };

  const handleLogin = () => {
    setIsHoverUserIcon(true);
  };
  const handleMouseLeave = () => {
    setIsHoverUserIcon(false);
  };

  return (
    <div className="fixed top-0 left-0 z-50 w-full bg-white shadow-md">
      <nav className="relative  w-full px-12  md:px-20 lg:px-10 xl:px-20 py-4 pt-5 flex justify-between items-center bg-[#030F30]">
        <div className="flex lg:hidden">
          <button
            className="flex items-center p-3 text-white navbar-burger"
            onClick={handleHideShowMenu}
          >
            <svg
              className="block w-4 h-4 fill-current"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Mobile menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
            </svg>
          </button>
        </div>
        <ul className="absolute hidden pl-10 text-white transform -translate-x-1/2 -translate-y-1/2 lg:pl-32 xl:pl-0 top-1/2 left-1/2 lg:mx-auto lg:flex lg:items-center lg:w-auto lg:space-x-6 md:text-base lg:text-lg-nav_head xl:text-xl-head_des 2xl:text-2xl-nav_head 3xl:text-3xl ">
          {navbarMenu.map((item) => {
            return (
              <div key={item.name} className="">
                <NavMenu list={item} />
              </div>
            );
          })}
        </ul>
        <NavLink to="/" >
          <img 
            src={ImageUrl.capSpringLogo}
            alt="logo"
            className="w-36 2xl:ml-20"
          />
        </NavLink>
        <div></div>
        {/* login */}
        {/* {user ?
          <div className="flex items-center text-white gap-x-4">
            <div className="z-50 flex items-center gap-2 px-3 py-2 text-white capitalize rounded-md top-16 right-10">
               
               {user}
             </div> 
            <BiLogOut size={25} className="cursor-pointer" title="Logout" onClick={() => {
              authService.logout();
              dispatch(getUserData(''))
              

            }}/>
           
             
          </div>
          : <div className="flex gap-4 text-white">

            <div className="cursor-pointer" onClick={() => dispatch(loginContext({
              isshow: true,
              redirectUrl: ""
            }))}>Login</div>
          </div>} */}
      </nav>

      {hideShowCloseBtn ? (
        <div className="relative z-50 navbar-menu ">
          <div className="fixed inset-0 opacity-25 navbar-backdrop"></div>
          <nav className="fixed w-full top-0 left-0 bottom-0 flex flex-col  max-w-sm py-6 px-6  bg-[#041330]  border-r overflow-y-auto">
            <div className="flex items-center mb-8">
              <NavLink className="mr-auto text-3xl font-bold leading-none">
                <img src={ImageUrl.logo} alt="logo" />
              </NavLink>
              <button
                className="flex items-center justify-center w-10 h-10 text-black bg-white rounded-full navbar-close"
                onClick={handleCloseMenu}
              >
                <svg
                  className="w-6 h-6 cursor-pointer hover:text-gray-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>
            </div>
            <div>
              <ul>
                {mobileMenu?.map((menu, index) => {
                  return (
                    <li key={index} className="mb-1">
                      <NavLink
                        to={menu.navigation}
                        target={menu.target}
                        className="block p-4 text-sm font-semibold text-white rounded"
                      >
                        {menu.name}
                      </NavLink>
                    </li>
                  );
                })}
              </ul>
            </div>
          </nav>
        </div>
      ) : null}
    </div>
  );
}

export default Navbar;
