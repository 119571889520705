import React from 'react'
import authService from '../../service/authService'
import { AiOutlineClose } from 'react-icons/ai';
import { ImageUrl } from '../../constants/url/ImagePath';
import { GoArrowLeft } from 'react-icons/go';
import { useForm } from "react-hook-form";
import { useState } from 'react';
import { AiFillEyeInvisible,AiFillEye } from 'react-icons/ai';
import { useDispatch } from 'react-redux'
import { loginContext } from '../../Redux/Slice/UserSlice';

const RegisterForm = (props) => {
    const dispatch = useDispatch()
    const [confirmPassword,setConfirmPassword]=useState("");
    const [confirmPasswordMessage,setConfirmPasswordMessage]=useState("");
    const [isRegisterFailed,setIsRegisterFailed]=useState("")
    const [isShowPassword,setIsShowPassword]=useState(false);
    const [isReShowPassword,setIsReShowPassword]=useState(false);
    const showPassword=()=>{
        setIsShowPassword(!isShowPassword);
    }
    const showRePassword=()=>{
        setIsReShowPassword(!isReShowPassword);
    }

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = (data) => {
        if(confirmPassword===""){
            setConfirmPasswordMessage("please re-enter the password");
            setTimeout(() => {
                setConfirmPasswordMessage("");
            }, 2000);
            return;
        }
        if(data.password!==confirmPassword){
            setConfirmPasswordMessage("Password mismatch")
            setTimeout(() => {
                setConfirmPasswordMessage("");
            }, 2000);
            return;
        }
        authService.register(data).then(res=>{
            if(res.message==="User added successfully"){
                props.setIsRegisterForm(false);
                return
            }
            setIsRegisterFailed(res.message);
            setTimeout(() => {
                setIsRegisterFailed("");
            }, 2000);
        })
    };
    return (
        <form onSubmit={handleSubmit(onSubmit)} >
            <div className="w-[500px] h-[500px]  flex flex-col gap-y-3 justify-between p-8 rounded-lg  bg-[#ECF2FE]    shadow-xl transition-all">
                <div className='flex justify-between w-full '>
                    <div></div>
                    <div className='text-[25px]  relative font-semibold  text-[#030F30] pt-2'>
                        <div className='absolute w-20 h-10  left-[20%] bottom-[60%] flex justify-center items-center'>
                            <img src={ImageUrl.waveImage} height="100%" width="100%" alt="" />
                        </div>
                        SPRING Lab</div>
                    <button type='button' onClick={()=>dispatch(loginContext({ isshow: false,  redirectUrl:""}))}><AiOutlineClose /></button>
                </div>

                <div className='flex flex-col w-full gap-y-4'>
                    <div className='relative flex flex-col w-full gap-y-1' >
                        <input type="text" id="email" name='email'
                            {...register("email", {
                                required: "Please enter your email",
                                pattern: {
                                    value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                                    message: "Please enter a valid email"
                                }
                            })}
                            placeholder='Enter the email address' className='w-full text-[13px] text-[#030F30] pl-2 bg-white rounded-lg outline-none h-9' />
                        {errors.email && <div className="absolute text-red-600 text-[12px] font-medium top-[95%]">{errors.email.message}</div>}
                    </div>
                    <div className='relative flex flex-col w-full gap-y-1' >
                        <input type="text" placeholder='Enter the Affiliation / Organization' className='w-full text-[13px] text-[#030F30] pl-2 bg-white rounded-lg outline-none h-9'
                            {...register("association", {
                                required: "Please enter the Affiliation / Organization",
                            })}

                        />
                        {errors.association && <div className="absolute text-red-600 text-[12px] font-medium top-[95%]">{errors.association.message}</div>}

                    </div>
                    {/* <div className='relative flex flex-col w-full gap-y-1' > */}
                        <input type="number"  value="1234567890"  hidden  placeholder='Enter the phone number' className='w-full pl-2 text-[13px] text-[#030F30] bg-white rounded-lg outline-none h-9'

                            {...register("mobile", {
                                required: "Please enter the phone number",
                                pattern: {
                                    value: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
                                    message: "Please enter a valid phone number"
                                }

                            })}
                        />
                        {/* {errors.mobile && <div className="absolute text-red-600 text-[12px] font-medium top-[95%]">{errors.mobile.message}</div>} */}
                    {/* </div> */}
                    <div className='relative flex flex-col w-full gap-y-1' >
                        <input  type={isShowPassword?"text":"password"} placeholder='Enter the passwod' className='w-full text-[13px] text-[#030F30] pl-2 bg-white rounded-lg outline-none h-9'
                            {...register("password", {
                                required: "Please enter the password",
                                minLength: {
                                    value: 5,
                                    message: "Password should be at-least 5 characters."
                                }
                            })}
                        />
                        {errors.password && <div className="absolute text-red-600 text-[12px] font-medium top-[95%]">{errors.password.message}</div>}
                      {isShowPassword?<AiFillEye onClick={showPassword} size="20"  className='absolute top-[25%] end-3 cursor-pointer'/>:<AiFillEyeInvisible onClick={showPassword} size="20"  className='absolute top-[25%] end-3 cursor-pointer'/>}  

                    </div>
                    <div className='relative flex flex-col w-full gap-y-1'  >
                        <input type={isReShowPassword?"text":"password"}onChange={(e)=>setConfirmPassword(e.target.value)} placeholder='Re-Enter the password' className='w-full text-[13px] text-[#030F30]  pl-2 bg-white rounded-lg outline-none h-9' 
                        />
                        <div className="absolute text-red-600 text-[12px] font-medium top-[95%]">{confirmPasswordMessage}</div>
                      {isReShowPassword?<AiFillEye onClick={showRePassword} size="20"  className='absolute top-[25%] end-3 cursor-pointer'/>:<AiFillEyeInvisible onClick={showRePassword} size="20"  className='absolute top-[25%] end-3 cursor-pointer'/>}  

                    </div>
                </div>
                <div className='w-full '>
                    <button type='submit' className={`w-full h-9 mt-4 bg-[#030F30] text-base  not-italic font-medium rounded-lg border  outline-none ${isRegisterFailed==""?"bg-[#030F30] text-white border-0":"bg-white text-red-500 border-red-500 "}`}> {isRegisterFailed==""?"Register":isRegisterFailed} </button>
                </div>
                <div className='w-full'>
                    <button type='button' onClick={() => props.setIsRegisterForm(false)} className='w-full outline-none h-9 bg-[#030F30] flex items-center justify-center gap-x-2 text-base text-white not-italic font-medium rounded-lg'>
                        <div><GoArrowLeft /></div>
                        <div>Login</div>
                    </button>
                </div>


            </div>
        </form>

    )
}

export default RegisterForm