import React from 'react';

function PastProjects() {
    return (
        <div>
            <div className='w-full h-[415px] 2xl:h-[500px] bg-white rounded-[20px] p-5 md:p-8  2xl:p-11 overflow-y-scroll hideScrollbar'>
                <div className='text-[#2b3547] text-[24px] 2xl:text-[28px] font-normal'>
                    Past Projects
                </div>
                <div className='text-[#1D293F] font-bold text-[16px] 2xl:text-[20px] mt-2 2xl:mt-3'>
                    Speech Based Access to Commodity Prices in Indian languages funded by MeitY    </div>
                <p className='text-[#1D293F] font-normal not-italic text-[16px] 2xl:text-[20px] leading-7'>
                    The Objective of this project is to help farmers stay updated with the latest prices of Agricultural Commodities and Weather Report by dealing a toll-free number and enquiring in their native language.    </p>
                <div className='text-[#1D293F] font-bold text-[16px] 2xl:text-[20px] mt-3 2xl:mt-4' >
                    Linear Transformation Approach to VTLN funded by Department of Science & Technology    </div>
                <div className='text-[#1D293F] font-bold text-[16px] 2xl:text-[20px] mt-3 2xl:mt-4' >
                    Front-end signal processing for robust speech recognition and speaker adaptation/normalization funded by Department of Science & Technology
                </div>
               
            </div>
        </div>
    )
}

export default PastProjects